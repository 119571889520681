import React, { useState } from 'react'
import "./Modal.css"
import toast from 'react-hot-toast';
import HttpClient from "../../utils/HttpClient";

function CareerForm({ closeCareModal }) {
    const initValue = {
        fullName: "",
        phoneNumber: "",
        email: "",
        role: "",
        cv: "",
        coverLater: "",
        portfolioLink: ""
    }
    const [formValue, setFormValue] = useState(initValue);
    console.log(formValue, "formvalue")
    const [error, setError] = useState();
    const validation = async () => {
        let error = {};
        const phoneRegex = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (formValue.fullName === "") {
            error.fullName = "full name Name required";
            return await toast.error("full name required");
        }

        if (!phoneRegex.test(formValue.phoneNumber) || formValue.phoneNumber === "") {
            error.phoneNumber = "Phone Number must be a 10-digit number";
            return await toast.error("Phone Number must be a 10-digit authentic number");
        }

        if (formValue.email === "") {
            error.email = "Email required";
            return await toast.error("email required");
        }
        if (!emailRegex.test(formValue.email)) {
            error.email = "Invalid email address";
            await toast.error("Invalid email address");
        }

        if (formValue.role === "") {
            error.email = "Role required";
            return await toast.error("Role required");
        }
        if (formValue.cv === "") {
            error.cv = "Cv required";
            return await toast.error("Cv required");
        }


        return error;
    };

    //taking values from input
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue({ ...formValue, [name]: value });
    };
    //file upload
    const handleFileChange = async (e) => {
        let file = e.target.files[0]
        let data = new FormData();
        data.append("pdf", file);

        let res = await HttpClient.fileUplode("upload-form-pdf", "POST", data);
        console.log("resultImg", res);
        if (res && res?.status) {

            let url = res?.data?.url;
            setFormValue(prev => ({ ...prev, cv: url }))
        } else {

            toast?.error(res?.message || "something wrong")
        }

    }
    const handleCoverLeterChange = async (e) => {
        let file = e.target.files[0]
        let data = new FormData();
        data.append("pdf", file);

        let res = await HttpClient.fileUplode("upload-form-pdf", "POST", data);
        console.log("resultImg", res);
        if (res && res?.status) {

            let url = res?.data?.url;
            setFormValue(prev => ({ ...prev, coverLater: url }))
        } else {

            toast?.error(res?.message || "something wrong")
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const err = await validation();
        setError(err);
        const data = {
            "name": formValue.fullName,
            "phoneNo": Number(formValue.phoneNumber),
            "email": formValue.email,
            "applyingRole": formValue.role,
            "cvLink": formValue.cv,
            "coverLetterlink": formValue.coverLater,
            "portfolioLink": formValue.portfolioLink
        }

        if (Object.keys(err).length === 0) {
            const res = await HttpClient.requestData("user-add-form-data", "POST", data)
            if (res && res?.status) {
                toast.success("Data submitted Successfully");
                setFormValue(initValue);

            } else {
                return toast.error(res?.message);
            }
            // console.log("ressDD", res);
        }
    }

    return (
        <section className='careerFormSection'>
            <div className='innerCareerFormDiv'>
                <button className='carerFormCloseBtn' onClick={() => closeCareModal(false)}><i class="fa-solid fa-xmark"></i></button>
                <p className='careFormHead'>Career Form</p>
                <form>
                    <div className='careerFormInpDiv'>
                        <input type='text' placeholder='Full Name' className='careerNormInp'
                            name='fullName' value={formValue?.fullName} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className='careerFormInpDiv'>
                        <input type='number' placeholder='Phone Number' className='careerNormInp'
                            value={formValue?.phoneNumber} name='phoneNumber' onChange={(e) => handleChange(e)} />
                    </div>
                    <div className='careerFormInpDiv'>
                        <input type='email' placeholder='@Email Id' className='careerNormInp'
                            value={formValue?.email} name='email' onChange={(e) => handleChange(e)} />
                    </div>
                    <div className='careerFormInpDiv'>
                        <input type='text' placeholder='The role you are applying for' className='careerNormInp'
                            value={formValue?.role} name='role' onChange={(e) => handleChange(e)} />
                    </div>
                    {/*CV*/}
                    <div className='careerFormInpDiv'>
                        <p className='careFormText'>Upload Your CV</p>
                        <input type='file' name='cv' onChange={handleFileChange} />
                    </div>
                    {/*Cover Leter*/}
                    <div className='careerFormInpDiv'>
                        <p className='careFormText'>Upload Cover Letter (Optional) </p>
                        <input type='file' onChange={handleCoverLeterChange} />
                    </div>
                    <div className='careerFormInpDiv'>
                        <input type='link' placeholder='Portfolio Link' className='careerNormInp'
                            value={formValue?.portfolioLink} name="portfolioLink" onChange={(e) => handleChange(e)} />
                    </div>
                    <div className='careSubBtnDiv'>
                        <button className='careSubBtn' onClick={(e) => handleSubmit(e)}>Submit</button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default CareerForm