import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Images/logo.png";

// component
import IndexImages from "../../Component/IndexImages";
import IndexSwiper from "../../Component/IndexSwiper";
import IndexClientsOld from "../../Component/IndexClientsOld";
import ClientSpeak from "../../Component/ClientSpeak";

function Index() {

  return (
    <>

      <IndexImages />

      {/* <IndexClientsOld /> */}

      <IndexSwiper />

      {/* <ClientSpeak /> */}

      <section className="indexlogo">
        <div className="custContain">
          <div className="indexlogomain">
            <img src={Logo} alt="img" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
