import React, { useEffect, useState } from "react";
import "../HomePage/Index.css";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import CustomLoader from "../../CustomComponents/loader/CustomLoader";
import HttpClient from "../../utils/HttpClient";

function GatewayCaseStudyInner() {

  const params = useParams()

  const [linkurl, setLinkUrl] = useState("");
  const [type, setType] = useState('')

  const [singleCaseStudy, setSingleData] = useState({});
  const [loading, setLoading] = useState(false);

  console.log("singleCaseStudyss", singleCaseStudy)

  // fetch singledata
  const getSingleCaseStudyDetails = async () => {
    setLoading(true);
    const res = await HttpClient.requestData("user-view-single-case-study/" + params.id, "GET");
    // console.log("resSinglecaseStudy", res);
    if (res && res?.status) {
      setSingleData(res?.data[0]);
      setLoading(false);
      if (!res?.data[0]?.video) {
        setType("img");
        setLinkUrl(res?.data[0]?.image[0])
      } else {
        setType("")
      }

      if (!res?.data[0]?.image[0]) {
        setLinkUrl("https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png")
      }
    } else {
      setLoading(false);
    }

  }

  useEffect(() => {
    getSingleCaseStudyDetails()
  }, [params?.id]);


  return (
    <>
      {/* <section className="Indexshowcase">
        <div className="custContain">
          <div className="showcasemain">
            <div className="showcaseleft">
              <div className="showcaseimg">
                <video
                  controls
                  className="w-100"
                >
                  <source src={video} type="video/mp4" />
                </video>
              </div>
              <div className="showimgDiv">

              </div>
            </div>

            <div className="showcaseright">
              <h3>
                Amul Case Study
              </h3>
              <p>
                Amul needed to have a stronger association with the
                Bengali consumers (one of their largest markets) and
                decided to use social media for that purpose. We started the first regional-language page of Amul in
                India and have been maintaining it since 2019, with varied
                content and advertising that goes deep into local bengali
                cultural nuances and connects with the audience strongly,
                making Amul Bangla one of the top Bengali brand sites on
                social media.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <CustomLoader loading={loading} />

      <section className="Indexshowcase">
        <div className="custContain">
          <div className="showcasemain">


            <div className="showcaseleft">
              <div className="showcaseimg">
                {
                  type == 'img' ?
                    <img src={linkurl} className="img-fluid" alt="" />
                    :
                    <ReactPlayer
                      className='showvideo'
                      controls
                      width='42rem'
                      height='24rem'
                      playing={type === "video" ? true : false}
                      url={singleCaseStudy?.video}
                      config={{
                        youtube: {
                          playerVars: { showinfo: 1 }
                        },
                        facebook: {
                          appId: '12345'
                        },
                      }}

                    />

                }
              </div>



              <div className="showimgDiv">


                <div>
                  {
                    singleCaseStudy?.video &&
                    <ReactPlayer
                      className='showvideo'

                      width='10rem'
                      height='8.9rem'
                      url={singleCaseStudy?.video}
                      playing={false}
                      onBuffer={() => {
                        console.log("object")
                        setType("video")
                      }}
                    />
                  }
                </div>


                {
                  singleCaseStudy?.image?.map((it, ind) => {
                    return (
                      <div className="showimgdivFlex"
                        key={ind}
                        onClick={() => {
                          setLinkUrl(it)
                          setType("img")
                        }}
                      >
                        <div className="showimgwrap" style={{ cursor: "pointer" }}>
                          <img src={it} alt="img" />
                        </div>

                        <div className="showText">
                          <p>{singleCaseStudy?.title}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>



            <div className="showcaseright">
              <h3>

                {singleCaseStudy?.subTitle}
              </h3>
              <p>
                {singleCaseStudy?.description}

              </p>
            </div>

          </div>
        </div>
      </section>




    </>
  )
}

export default GatewayCaseStudyInner