import React from "react";
import "../HomePage/Index.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import camp1 from "../../Images/Campaign1.jpg";
import camp2 from "../../Images/Campaign2.jpg";
import camp3 from "../../Images/Campaign3.jpg";
import camp4 from "../../Images/Campaign4.jpg";
import camp5 from "../../Images/Campaign5.jpg";
import camp6 from "../../Images/Campaign6.jpg";
import camp7 from "../../Images/Campaign7.jpg";
import camp8 from "../../Images/Campaign8.jpg";
import camp9 from "../../Images/Campaign9.jpg";
import camp10 from "../../Images/Campaign10.jpg";


function IndexCamp() {
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  const camplist = [
    {
      id: 1,
      src: camp1,
      alt: "img",
      campname: "Top Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 2,
      src: camp2,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/LightGallery",
    },
    {
      id: 3,
      src: camp3,
      alt: "img",
      campname: "Ruskit Campaign",
      pagelink: "/HomePage/CampPoster",
    },
    {
      id: 4,
      src: camp4,
      alt: "img",
      campname: "Top Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 5,
      src: camp5,
      alt: "img",
      campname: "50-50 Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 6,
      src: camp6,
      alt: "img",
      campname: "Half Half Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 7,
      src: camp7,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 8,
      src: camp8,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 9,
      src: camp9,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 10,
      src: camp10,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 11,
      src: camp1,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 12,
      src: camp2,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 13,
      src: camp3,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 14,
      src: camp4,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 15,
      src: camp5,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 16,
      src: camp6,
      alt: "img",
      campname: "Googly Campaign",
      pagelink: "/HomePage/CampDetails",
    },
    {
      id: 17,
      src: camp7,
      alt: "img",
      campname: "Googly TVC",
      pagelink: "/HomePage/CampPoster",
    },
    {
      id: 18,
      src: camp8,
      alt: "img",
      campname: "All packaging 2023-24",
      pagelink: "/HomePage/LightGallery",
    },
  ];

  return (
    <>
      <section className="indexCamp">
        <div className="custContain">
          <div className="camprow">
            {camplist.map((image) => {
              return (
                <div className="campCol">
                  <Link to={image.pagelink}>
                    <div className="campDiv">
                      <div className="campImg">
                        <img key={image.id} src={image.src} alt={image.alt} />
                      </div>
                      <div className="campText">
                        <p>{image.campname}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          {isShowMore && (
            <div className="row">
              {camplist.map((image) => {
                return (
                  <div className="campCol">
                    <Link to={image.pagelink}>
                      <div className="campDiv">
                        <div className="campImg">
                          <img key={image.id} src={image.src} alt={image.alt} />
                        </div>
                        <div className="campText">
                          <p>{image.campname}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
          {/* <div className="clickmore">
            <Link onClick={toggleReadMoreLess} className="clickmorelink">
              {isShowMore ? "Click for less" : "Click for more"}
            </Link>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default IndexCamp;
