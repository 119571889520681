import React from 'react'
import profilePic from "../../Images/Kusumasab Mitra.jpg";

function ThoughtLeadersModal(props) {
    const { closeLeaders } = props;
    const { singleData } = props;

    console.log("singleDatafff", singleData)
    return (
        <>
            <section className='thoughtLeaders_Modal'>
                <div className='thoughtLeadersmodal_body'>
                    <button className='closebtn' onClick={() => closeLeaders(false)} >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    {/* <h2 className='heading'>Thought Leaders</h2> */}

                    <div className='content_wrap'>
                        <div className='maincontent'>
                            <figure className='profile_pic'>
                                <img src={singleData.image ? singleData.image : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} className='img-fluid' alt='....' />
                            </figure>
                            <div className='profile_details'>
                                <h4 className='name'>{singleData?.name}</h4>
                                <h6 className='subtitle'>{singleData?.designation}</h6>
                                <p className='detail'>{singleData?.description}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default ThoughtLeadersModal