import React, { useState } from 'react'
import toast from 'react-hot-toast';
import HttpClient from '../utils/HttpClient';
import CustomLoader from '../CustomComponents/loader/CustomLoader';

function Getquoteform() {

    const initValue = {
        fullName: "",
        email: "",
        companyName: "",
        projectOverview: "",
        phoneNumber: "",
        whereDidYoyAboutUs: ""
    }
    const [formValue, setFormValue] = useState(initValue);
    const [isLoading, setIsLoading] = useState(false);

    console.log("formValued", formValue)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue(prev => ({ ...prev, [name]: value }));
    }

    const validate = () => {
        if (!formValue?.fullName) {
            toast.error("Full Name is Required");
            return true
        }
        if (!formValue?.email) {
            toast.error("Emil is Required");
            return true
        }
        if (!formValue?.companyName) {
            toast.error("Company Name is Required");
            return true
        }
        if (!formValue?.phoneNumber) {
            toast.error("Phone Number is Required");
            return true
        }

        return false
    }

    // submit
    const handleSubmit = async (e) => {
        console.log("submit")
        e.preventDefault();

        if (validate()) {
            return
        }
        setIsLoading(true);
        const data = {
            fullName: formValue.fullName,
            email: formValue.email,
            companyName: formValue.companyName,
            projectOverview: formValue.projectOverview,
            phoneNumber: formValue.phoneNumber,
            whereDidYoyAboutUs: formValue.whereDidYoyAboutUs
        }


        const res = await HttpClient.requestData("add-quote", "POST", data);
        // console.log("ressqu", res);
        if (res && res?.status) {
            toast.success("Quote Added Successfully");
            setFormValue(initValue);
            window.scrollTo(0, 0);
            setIsLoading(false);

        } else {
            toast.error(toast.message || "something wrong");
        }
    }

    return (
        <>
            <CustomLoader loading={isLoading} />

            <div className='getquoteform'>
                <form>
                    <div className='form-group'>
                        <input
                            type='text'
                            placeholder='Full name'
                            name='fullName'
                            value={formValue?.fullName}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                    <div className='form-group'>
                        <input
                            type='email'
                            placeholder='E-mail ID'
                            name='email'
                            value={formValue?.email}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                    <div className='form-group'>
                        <input
                            type='text'
                            placeholder='Company Name'
                            name='companyName'
                            value={formValue?.companyName}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                    <div className='form-group'>
                        <input
                            type='text'
                            placeholder='Phone Number'
                            name='phoneNumber'
                            value={formValue?.phoneNumber}
                            onChange={(e) => handleChange(e)}
                            maxLength={10}
                        />
                    </div>

                    <div className='form-group'>
                        <textarea
                            rows='6'
                            placeholder='Project Overview'
                            name='projectOverview'
                            value={formValue?.projectOverview}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                    <div className='form-group'>
                        <textarea
                            rows='4'
                            placeholder='Where did you hear about us'
                            name='whereDidYoyAboutUs'
                            value={formValue?.whereDidYoyAboutUs}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                    <button className='getquoteSubmit' onClick={handleSubmit}>Submit</button>
                </form>
            </div>
        </>
    )
}

export default Getquoteform
