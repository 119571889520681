import Workmain from '../../Component/Workmain'
import WorkSidebar from '../../Component/WorkSidebar'


import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
// import "../View/Work/Work.css";
import "./Work.css"

// Component

import WorkBlock from '../../Component/WorkBlock';
import HttpClient from "../../utils/HttpClient";
import CustomLoader from '../../CustomComponents/loader/CustomLoader';
import { toast } from "react-hot-toast";


const SearchWork = () => {
  const params = useParams();

  const [workData, setWorkData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);

  // console.log("params", params)

  const wrkResSideOpen = () => {
    document.querySelector(".workPageSidebar").style.cssText +=
      "transform: translate(0, 0);";
  };

  const wrkResSideClose = () => {
    document.querySelector(".workPageSidebar").style.cssText +=
      "transform: translate(-350px, 0);";
  };

  // fetch all work
  const getWork = async () => {
    setLoading(true);
    const res = await HttpClient.requestData("user-view-work", "GET", {});
    // console.log("resUserw", res);
    if (res && res?.status) {
      setWorkData(res?.data);
      setFilterData(res?.data)
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  // fetch all work searched
  const getSearchedWork = async () => {

    setLoading(true);
    const sendData = {
      fieldName: params?.fieldName,
      id: params?.id
    }
    const res = await HttpClient.requestData("user-get-selected", "POST", sendData);
    // console.log("resUserwsserch", res);
    if (res && res?.status) {
      setWorkData(res?.data);
      setFilterData(res?.data)
      setLoading(false);
    } else {
      setLoading(false);
    }
  }


  //get all brand
  const getBrand = async () => {
    const res = await HttpClient.requestData("user-view-category", "GET", {})
    if (res && res?.status) {
      setBrandData(res?.data)
    }
    else {
      toast.error(res?.message)
    }
  }

  //get all industry
  const getIndustry = async () => {
    const res = await HttpClient.requestData("user-view-industry", "GET", {})
    // console.log(res,'resndck')
    if (res && res.status) {
      setIndustryData(res?.data)
    }
    else {
      toast.error(res?.message)
    }
  }

  //get all media
  const getMedia = async () => {
    const res = await HttpClient.requestData("user-view-media", "GET", {})
    //  console.log(res,"jhjhjknh")
    if (res && res.status) {
      setMediaData(res?.data)
    }
    else {
      toast.error(res?.message)
    }
  }

  //for filtering brand
  // const brandWiseCatData = (id) => {
  //   // console.log("brandID", id)
  //   let filtdata = []
  //   filtdata = workData?.filter((item) => item?.catID === id)
  //   // console.log(filtdata, 'filtDatar')
  //   setFilterData(filtdata)
  // }

  //for filtering industry
  // const industryWiseCatData = (id) => {
  //   // console.log("industryIDddd", id)
  //   let filtdata = []
  //   filtdata = workData?.filter((item) => item?.industryID === id)
  //   console.log(filtdata, 'filtDataa')
  //   setFilterData(filtdata)
  // }

  //for filtering media
  // const mediaWiseCatData = (id) => {
  //   // console.log("hbjhsbcs",id)
  //   let filtData = []
  //   filtData = workData?.filter((item) => item?.mediaID === id)
  //   console.log(filtData)
  //   setFilterData(filtData)
  // }

  //for filtering language
  const languageWiseCatData = (language) => {
    // console.log("fvfvv",language)
    let filtData = []
    filtData = workData?.filter((item) => item?.language === language)
    console.log(filtData)
    setFilterData(filtData)
  }

  useEffect(() => {
    getSearchedWork();
  }, [params]);

  useEffect(() => {
    // getWork();
    getBrand();
    getIndustry();
    getMedia();
  }, [])

  return (
    <>
      <CustomLoader loading={loading} />

      <section className="workDiv">
        <div className="custContain">
          <div className="row">
            <div className="col-xl-2 d-xl-block d-none workflex">

              {/* sidebar Part */}

              {/* <Accordion className="workSidebarAccr">

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span className="workInduSpan">Brand</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ul className="workSidebarIndUl">

                      {
                        brandData?.map((item, i) =>
                          <li
                            className="workSideBarLi"
                            onClick={() => brandWiseCatData(item?._id)}
                            key={i} >
                            <div className="workSideBarLiAn" >
                              {item?.name}
                            </div>
                          </li>
                        )
                      }

                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span className="workInduSpan">Industry</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ul className="workSidebarIndUl">

                      {
                        industryData?.map((item, index) => {
                          return (<>
                            <li className="workSideBarLi" key={index} onClick={() => industryWiseCatData(item?._id)}>
                              <Link className="workSideBarLiAn">
                                {item?.name}
                              </Link>
                            </li>
                          </>)
                        })
                      }
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span className="workInduSpan">Language</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ul className="workSidebarIndUl">
                      <li className="workSideBarLi" onClick={() => languageWiseCatData("English")}>
                        <Link className="workSideBarLiAn">
                          English
                        </Link>
                      </li>
                      <li className="workSideBarLi" onClick={() => languageWiseCatData("Hindi")}>
                        <Link className="workSideBarLiAn">
                          Hindi
                        </Link>
                      </li>
                      <li className="workSideBarLi" onClick={() => languageWiseCatData("Bengali")}>
                        <Link className="workSideBarLiAn">
                          Bengali
                        </Link>
                      </li>
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span className="workInduSpan">Media</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ul className="workSidebarIndUl">
                      {
                        mediaData?.map((item, index) => {
                          return (
                            <>
                              <li className="workSideBarLi" key={index} onClick={() => mediaWiseCatData(item?._id)}>
                                <Link className="workSideBarLiAn">
                                  {item?.name}
                                </Link>
                              </li>
                            </>
                          )
                        })
                      }


                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>

              </Accordion> */}

            </div>
            <div className="col-xl-10 col-12 workflex">
              <div className="workPageToggleDiv">
                <button className="workPageToggleBtn" onClick={wrkResSideOpen}>
                  Categories
                </button>
              </div>

              {/* works */}
              <WorkBlock filterData={filterData} />
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default SearchWork
