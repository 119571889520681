import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

import Amul from "../Images/amul-logo.png";
import Cars24 from "../Images/Cars 24_B-01.png";
import Dabur from "../Images/DABUR.png";
import Indiagate from "../Images/indiagate.jpg";
import Paytm from "../Images/Paytm.jpg";
import Raymond from "../Images/Raymond.jpg";
import Seagram from "../Images/Seagrams logo.jpg";
import Sunstone from "../Images/Sunstone-logo.jpg";
// import arrowprev from "../Images/Clientarrowprev.png";
// import arrownext from "../Images/Clientarrownext.png";
import arrowprev from "../Images/arrowleft.png";
import arrownext from "../Images/arrowright.png";
import HttpClient from "../utils/HttpClient";
import { useNavigate } from "react-router-dom";


function GatewaySwiperLeft() {
  const navigate = useNavigate();

  const [swiperRef, setSwiperRef] = useState(null);
  const [categoryData, setcategoryData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  console.log("Category", categoryData, "SLICE", categoryData.slice(0, 9))


  const prevHandler = () => {
    swiperRef.slidePrev();
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 8, 0));
  };

  const nextHandler = () => {
    swiperRef.slideNext();
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 9, categoryData.length - 1));
  };

  // const loop = [
  //   { id: 1, src: Amul, alt: "img" },
  //   { id: 2, src: Cars24, alt: "img" },
  //   { id: 3, src: Dabur, alt: "img" },
  //   { id: 4, src: Indiagate, alt: "img" },
  //   { id: 5, src: Paytm, alt: "img" },
  //   { id: 6, src: Raymond, alt: "img" },
  //   { id: 7, src: Seagram, alt: "img" },
  //   { id: 8, src: Sunstone, alt: "img" },
  // ];


  const getCategoryData = async () => {
    const res = await HttpClient.requestData('user-view-category', "GET", {});
    console.log(res)
    if (res && res?.status) {
      setcategoryData(res?.data?.filter((item) => item?.type == "National Brand"
      ))

    }
  }
  useEffect(() => {
    getCategoryData();
  }, []);

  return (
    <>
      <section className="gatwaySwiper">
        <div className="arrowprevgate" onClick={prevHandler}>
          <img src={arrowprev} alt="left" />
        </div>
        <Swiper
          slidesPerView={1}
          direction={"vertical"}
          loop={true}
          pagination={{
            clickable: false,
          }}
          navigation={false}
          modules={[Navigation]}
          onSwiper={(swiper) => setSwiperRef(swiper)}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="gatewayRow row">
              {categoryData.slice(currentIndex, currentIndex + 9).map((image, index) => {
                return (
                  <div className="gatewayCol col-sm-4 col-4" key={index}>
                    <div className="gatewrap" style={{ cursor: "pointer" }} onClick={() => navigate("/Work", { state: { brandId: image?._id } })}>
                      <img key={image._id} src={image?.logoUrl} alt={"image"} />
                      {/* {image.name} */}
                    </div>
                  </div>
                );
              })}
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="gatewayRow row">
              {categoryData.map((image, index) => {
                return (
                  <div className="gatewayCol col-sm-4 col-4" key={index}>
                    <div className="gatewrap" style={{ cursor: "pointer" }} onClick={() => navigate("/Work", { state: { brandId: image?._id } })}>
                      {image.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </SwiperSlide> */}
        </Swiper>
        <div className="arrownextgate" onClick={nextHandler}>
          <img src={arrownext} alt="right" />
        </div>
      </section>
    </>
  );
}

export default GatewaySwiperLeft;
