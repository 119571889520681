import React from "react";
import "../Getquote/Getquote.css";
import Getquoteform from "../../Component/Getquoteform";

function Getquote() {
  return (
    <>
      <section className="getquote">
        <div className="custContain">
          <div className="getquotebody">
            <div className="getquotetext">
              <h5 className="getquotehead">Like what you see?</h5>
              <h5 className="getquotehead">Let’s connect!</h5>
              <p className="getquotepara">Our doors are wide open, eagerly waiting to discuss
                your next big project and breathe life into it! </p>
            </div>

            <Getquoteform />
          </div>
        </div>
      </section>
    </>
  );
}

export default Getquote;
