import React from 'react'
import profilePic from "../../Images/Amulmain.jpg";
import ReactPlayer from 'react-player';

function WorkModal(props) {
    const { closeWorkmodal } = props;
    const { singleWork } = props;
    const { linkurl } = props;
    const { type } = props;
    return (
        <>
            <section className='workModal_wrap'>
                <div className='workModal_body'>
                    <button className='closebtn' onClick={() => closeWorkmodal(false)} >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <h2 className='heading'>{singleWork?.title}</h2>
                    <div className='content_wrap'>

                        <div className='profile_pic'>
                            {
                                type == 'img' ?
                                    <img src={linkurl} className="img-fluid" alt="" />
                                    :
                                    <ReactPlayer
                                        className='vdo-fluid'
                                        controls
                                        width='42rem'
                                        height='24rem'
                                        playing={type === "video" ? true : false}
                                        url={singleWork?.video}
                                        config={{
                                            youtube: {
                                                playerVars: { showinfo: 1 }
                                            },
                                            facebook: {
                                                appId: '12345'
                                            },
                                        }}

                                    />

                            }
                            {/* <img src={linkurl} className='img-fluid' alt='....' /> */}
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default WorkModal