import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";
// import aaroananda from "../Images/Aaro Anondo.jpg";
// import Independence from "../Images/Abp Independence Day.png";
// import abpmags from "../Images/anandalok.jpg";
// import pujocampaign from "../Images/Desh.jpg";
// import abpcom from "../Images/abpcom.jpg";
// import anandamela from "../Images/Anandamela.jpg";
// import Deshpujo from "../Images/deshpujo.jpg";
// import Pujabarshiki from "../Images/pujabarshiki.jpg";
// import Centenaryad from "../Images/ABPDaily.jpg";
// import ABPWeddings from "../Images/ABP WEDDINGS.jpeg";
import arrowleft from "../Images/arrowleft.png";
import arrowright from "../Images/arrowright.png";
import HttpClient from "../utils/HttpClient";

function WorkInnerSlider({ categoryId, singleWork }) {
    const [swiperRef, setSwiperRef] = useState(null);
    const prevHandler = () => {
        swiperRef.slidePrev();
    };

    const nextHandler = () => {
        swiperRef.slideNext();
    };

    const [catWiseData, setCatWiseData] = useState([]);
    // console.log("catWiseData", catWiseData)

    const getWorkFromCatId = async () => {
        const res = await HttpClient.requestData("cat-wise-view-work/" + categoryId, "GET");
        // console.log("resCatWork", res);
        if (res && res?.status) {
            setCatWiseData(res?.data)
        } else {

        }
    }

    useEffect(() => {
        if (categoryId) getWorkFromCatId();
    }, [categoryId])

    return (
        <>
            {catWiseData?.length !== 0 &&
                <section className="showSlider">
                    <div className="custContain">
                        <div className="showSliderTop">
                            <h3 className="showSliderHead">{singleWork?.title}</h3>
                        </div>
                        <div className="showSliderbtm">
                            <div className="showprev" onClick={prevHandler}>
                                <img src={arrowleft} />
                            </div>
                            <Swiper
                                slidesPerView={4}
                                spaceBetween={10}
                                loop={true}
                                navigation={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    300: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    575: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 10,
                                    },
                                    1399: {
                                        slidesPerView: 4,
                                        spaceBetween: 10,
                                    },
                                }}
                                modules={[Navigation]}
                                onSwiper={(swiper) => setSwiperRef(swiper)}
                                className="showSwiper"
                            >
                                {
                                    catWiseData?.map((item, i) =>
                                        <SwiperSlide key={i}>
                                            <div className="showsliderwrap">
                                                <Link to={`/WorkInner/${item?._id}`}>
                                                    <img src={item?.image[0]} alt="img" />
                                                    <div className="showsliderText">
                                                        <p>{item?.title}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }

                                {/* <SwiperSlide>
                                <div className="showsliderwrap">
                                    <Link to='/WorkInner'>
                                        <img src={Independence} alt="img" />
                                        <div className="showsliderText">
                                            <p>Independence Day</p>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="showsliderwrap">
                                    <Link to='/WorkInner'>
                                        <img src={abpmags} alt="img" />
                                        <div className="showsliderText">
                                            <p>ABP Mags</p>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="showsliderwrap">
                                    <Link to='/WorkInner'>
                                        <img src={pujocampaign} alt="img" />
                                        <div className="showsliderText">
                                            <p>Pujo Campaign 2022</p>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="showsliderwrap">
                                    <Link to='/WorkInner'>
                                        <img src={abpcom} alt="img" />
                                        <div className="showsliderText">
                                            <p>ABP.com Campaign</p>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="showsliderwrap">
                                    <Link to='/WorkInner'>
                                        <img src={anandamela} alt="img" />
                                        <div className="showsliderText">
                                            <p>Anandamela Pujo</p>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="showsliderwrap">
                                    <Link to='/WorkInner'>
                                        <img src={Deshpujo} alt="img" />
                                        <div className="showsliderText">
                                            <p>Desh Pujo</p>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="showsliderwrap">
                                    <Link to='/WorkInner'>
                                        <img src={Pujabarshiki} alt="img" />
                                        <div className="showsliderText">
                                            <p>Pujabarshiki</p>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="showsliderwrap">
                                    <Link to='/WorkInner'>
                                        <img src={Centenaryad} alt="img" />
                                        <div className="showsliderText">
                                            <p>Centenary Ads</p>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="showsliderwrap">
                                    <Link to='/WorkInner'>
                                        <img src={ABPWeddings} alt="img" />
                                        <div className="showsliderText">
                                            <p>ABPWeddings.com</p>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide> */}
                            </Swiper>
                            <div className="shownext" onClick={nextHandler}>
                                <img src={arrowright} />
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default WorkInnerSlider