import React, { useState } from "react";
import "../HomePage/Index.css";
import googly1 from "../../Images/Bisk Farm_Googly1.jpg";
import googly2 from "../../Images/Bisk Farm_Googly2.jpg";
import biskthumb from "../../Images/biskthumb.jpg";
import ShowSliderBisk from "../../Component/ShowSliderBisk";

const data = [
  {
    title: 'Googly',
    image: biskthumb,
    link: "https://www.youtube.com/embed/14JU5prlZmY?si=0zbHmzXat3mLNg9p",
    type: 'video'
  },
  {
    title: 'Googly',
    image: googly1,
    link: googly1,
    type: 'img'
  },
  {
    title: 'Googly',
    image: googly2,
    link: googly2,
    type: 'img'
  },
]

function Biskfarm() {
  const [linkurl, setLinkUrl] = useState("https://www.youtube.com/embed/14JU5prlZmY?si=0zbHmzXat3mLNg9p")
  const [type, setType] = useState('video')
  return (
    <>
      <section className="Indexshowcase">
        <div className="custContain">
          <div className="showcasemain">
            <div className="showcaseleft">
              <div className="showcaseimg">
                {
                  type == 'img' ?
                    <img src={linkurl} className="img-fluid" alt="" />
                    :
                    <iframe
                      src={linkurl}
                      title="YouTube video player"
                      frameBorder={0}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen=""
                      className="showvideo"
                    />}

              </div>
              <div className="showimgDiv">

                {
                  data?.map((it, ind) => {
                    return (
                      <div className="showimgdivFlex"
                        key={ind}
                        onClick={() => {
                          setLinkUrl(it.link)
                          setType(it.type)
                        }}
                      >
                        <div className="showimgwrap">
                          <img src={it.image} alt="img" />
                        </div>
                        <div className="showText">
                          <p>{it?.title}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className="showcaseright">
              <h3>
                Bisk Farm - Googly Campaign With Hrithik Roshan (TV, Print,
                Outdoor)
              </h3>
              <p>
                In its pursuit of nationwide presence, Bisk Farm roped in
                HrithikRoshan as the brand ambassador for their innovative
                'Googly' biscuits, which disrupt the traditional biscuit
                category. The brand's proposition of "Taste with a twist"
                perfectly encapsulates the unexpected addition of kalonji or
                black cumin in an otherwise sweet biscuit. To showcase Hrithik's
                extraordinary dancing skills, we translated this twist into a
                viral misstep by him, adding a fun and engaging element to the
                campaign.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ShowSliderBisk />
    </>
  );
}

export default Biskfarm;
