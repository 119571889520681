import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper/modules";

import ambuja from "../Images/AMBUJA NEOTIA.jpg";
import anandabazar from "../Images/ANANDABAZAR PATRIKA.jpg";
import bandhan from "../Images/BANDHAN BANK logo.jpg";
import bengalgovt from "../Images/BENGAL GOVT.jpg";
import biskfarm from "../Images/BISK FARM.jpeg";
import boudh from "../Images/BOUDH.jpg";
import colorsbangla from "../Images/Colors_Bangla.png";
import ganesh from "../Images/GANESH GRAINS.jpg";
import keokarpin from "../Images/Keo Karpin logo.png";
import pcchandra from "../Images/PC CHANDRA JEWELLERS.jpg";
import shyammetal from "../Images/SHYAM METALLICS.jpg";
// import arrowprev from "../Images/Clientarrowprev.png";
// import arrownext from "../Images/Clientarrownext.png";
import arrowprev from "../Images/arrowleft.png";
import arrownext from "../Images/arrowright.png";
import HttpClient from "../utils/HttpClient";
import { useNavigate } from "react-router-dom";


function GatewaySwiperRight() {
    const navigate = useNavigate();

    const [swiperRef, setSwiperRef] = useState(null);
    const [categoryData, setcategoryData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    console.log("Category", categoryData, "SLICE", categoryData.slice(0, 9))
    console.log("Regional", categoryData)
    const prevHandler = () => {
        swiperRef.slidePrev();
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 9, 0));
    };

    const nextHandler = () => {
        swiperRef.slideNext();
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 9, categoryData.length - 1));
    };


    // const loop = [
    //     { id: 1, src: ambuja, alt: "img" },
    //     { id: 2, src: anandabazar, alt: "img" },
    //     { id: 3, src: bandhan, alt: "img" },
    //     { id: 4, src: bengalgovt, alt: "img" },
    //     { id: 5, src: biskfarm, alt: "img" },
    //     { id: 6, src: boudh, alt: "img" },
    //     { id: 7, src: colorsbangla, alt: "img" },
    //     { id: 8, src: ganesh, alt: "img" },
    //     { id: 9, src: keokarpin, alt: "img" },
    // ];

    // const loop2 = [
    //     { id: 10, src: pcchandra, alt: "img" },
    //     { id: 11, src: shyammetal, alt: "img" },
    // ];
    const getCategoryData = async () => {
        const res = await HttpClient.requestData('user-view-category', "GET", {});
        console.log(res)
        if (res && res?.status) {
            setcategoryData(res?.data?.filter((item) => item?.type == "Regional Brand"))

        }
    }
    useEffect(() => {
        getCategoryData();
    }, []);

    return (
        <>
            <section className="gatwaySwiper">
                <div className="arrowprevgate" onClick={prevHandler}>
                    <img src={arrowprev} />
                </div>
                <Swiper
                    slidesPerView={1}
                    direction={"vertical"}
                    loop={true}
                    pagination={{
                        clickable: false,
                    }}
                    navigation={false}
                    modules={[Navigation]}
                    onSwiper={(swiper) => setSwiperRef(swiper)}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="gatewayRow row">
                            {categoryData.slice(currentIndex, currentIndex + 9).map((image, index) => {
                                return (
                                    <div className="gatewayCol col-sm-4 col-4" key={index}>
                                        <div className="gatewrap" style={{ cursor: "pointer" }} onClick={() => navigate("/Work", { state: { brandId: image?._id } })}>
                                            <img key={image._id} src={image?.logoUrl} alt={"image"} />
                                            {/* {image.name} */}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                            <div className="gatewayRow row">
                            {categoryData.map((image,index) => {
                                    return (
                                        <div className="gatewayCol col-sm-4 col-4" key={index}>
                                            <div className="gatewrap">
                                                <img key={image._id} src={image?.logoUrl}  />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </SwiperSlide> */}
                </Swiper>
                <div className="arrownextgate" onClick={nextHandler}>
                    <img src={arrownext} />
                </div>
            </section>
        </>
    )
}

export default GatewaySwiperRight