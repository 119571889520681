import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "../src/View/HomePage/Index.css";
import "../src/Component/Modal/Modal.css";
import MainLayout from "../src/Layout/MainLayout";
import Homepage from "../src/View/HomePage/Index";
import About from "../src/View/About/About";
import InnerLayout from "./Layout/InnerLayout";
import People from "./View/People/People";
import PeopleInner from "./View/People/PeopleInner"
import Gateway from "./View/Gateway/Gateway";
import Work from "./View/Work/Work";
import Career from "./View/Career/Career";
import News from "./View/News/News";
import GatewayCaseStudyInner from "./View/Gateway/GatewayCaseStudyInner";
import GatewayCase from "./View/Gateway/GatewayCase";
import Getquote from "./View/Getquote/Getquote";
import IndexCamp from "./View/HomePage/IndexCamp";
import CampDetails from "./View/HomePage/CampDetails";
import LightGallery from "./View/HomePage/LightGallery";
import CampPoster from "./View/HomePage/CampPoster";
import NewsDetails from "./View/News/NewsDetails";
import Contact from "./View/Contact/Contact";
import Biskfarm from "./View/HomePage/Biskfarm";
import Rupa from "./View/HomePage/Rupa";
import WorkInner from "./View/Work/WorkInner";
import { Toaster } from "react-hot-toast";
import ScrollIntoTop from "./CustomComponents/ScrollIntoTop";
import SearchWork from "./View/Work/SearchWork";


function App() {
  return (
    <React.Fragment>
      <Router>
        <ScrollIntoTop />
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/HomePage/IndexCamp" element={<IndexCamp />}></Route>
            <Route path="/HomePage/CampDetails" element={<CampDetails />}></Route>
            <Route path="/HomePage/LightGallery" element={<LightGallery />}></Route>
            <Route path="/HomePage/CampPoster" element={<CampPoster />}></Route>
            <Route path="/News/NewsDetails" element={<NewsDetails />}></Route>
            <Route path="/About" element={<About />} />
            <Route path="/People" element={<People />} />
            <Route path="/Gateway" element={<Gateway />} />
            <Route path="/case-study-details/:id" element={<GatewayCaseStudyInner />} />
            <Route path="/Work" element={<Work />}></Route>
            <Route path="/search-work/:id/:fieldName/:name" element={<SearchWork />}></Route>
            <Route path="/Career" element={<Career />}></Route>
            <Route path="/News" element={<News />}></Route>
            <Route path="/Contact" element={<Contact />}></Route>
            <Route path="/Gateway/GatewayCase" element={<GatewayCase />}></Route>
            <Route path="/Getquote" element={<Getquote />}></Route>
            <Route path="/people/:name" element={<PeopleInner />} />
            <Route path="/Biskfarm" element={<Biskfarm />} />
            <Route path="/Rupa" element={<Rupa />} />
            <Route path="/WorkInner/:id" element={<WorkInner />} />
          </Route>
          <Route element={<InnerLayout />}>

          </Route>
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
