import React from "react";
import { Link } from "react-router-dom";
import "../News/News.css";
import newsdetails1 from "../../Images/newsDet6.png";
import newsdetails2 from "../../Images/newsDet2.png";
import newsdetails3 from "../../Images/newsDet3.png";
import newsdetails4 from "../../Images/newsDet9.jpg";

function NewsDetails() {
  return (
    <>
      <>

      {/* news banner */}

        <section className="banner">
          <div className="custContain">
            <div className="banner-img">
              <iframe
                src="https://www.youtube.com/embed/3weDgT_MhKA?si=ktjII5WjAwlhYDSW"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen=""
              />
            </div>
          </div>
        </section>


       {/* news article */}

        <section className="award-conten">
          <div className="custContain">
            <div className="award-title">
              <h3>Fox Glove Awards</h3>
              <span>23rd July, 2023</span>
            </div>
            <div className="award-dtls">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem
                veniam similique ab explicabo maiores et voluptas nesciunt
                adipisci dolores, iusto illum soluta, accusamus sed ratione
                ullam! Ut voluptate vel ullam! Lorem ipsum dolor sit amet,
                consectetur adipisicing elit. Qui quaerat rerum at alias harum.
                Voluptate nihil facilis quam praesentium ex voluptates ratione
                eos libero illo possimus excepturi officia, eius tempore. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Quod quos,
                dolores unde veritatis dolorum iusto iste dolore inventore
                possimus culpa dicta eligendi facere aliquid fuga cupiditate
                odio facilis libero illo. Lorem, ipsum dolor sit amet
                consectetur adipisicing elit. Impedit ipsum error reiciendis
                quos blanditiis exercitationem eaque nihil nam quam modi aliquid
                provident explicabo id sequi sunt soluta dolores, iusto
                praesentium. Lorem ipsum dolor, sit amet consectetur adipisicing
                elit. Autem aut quae adipisci perferendis exercitationem
                recusandae et ut provident praesentium! Consectetur soluta
                consequuntur quia obcaecati nulla quo ratione dignissimos,
                voluptates ex! Eveniet odio explicabo quasi totam libero
                consectetur illo laudantium temporibus esse assumenda, aliquam
                perspiciatis natus, voluptatum ipsum, tempora vitae rem omnis
                vero. Aperiam id at quo fugiat! Sequi, amet ab. Sapiente
                molestiae atque laboriosam doloremque tenetur veniam consectetur
                voluptatum cum adipisci natus maxime quae enim labore libero ab,
                sunt quaerat nulla obcaecati quisquam earum, sint repellendus
                praesentium! Odit, quo quam? Velit labore recusandae
                repudiandae, quo veniam rerum iure deleniti ipsum odit fuga
                inventore nam facere consequatur nihil porro tempora laboriosam
                itaque voluptatibus veritatis suscipit quidem.
              </p>
            </div>
          </div>
        </section>
        <section className="related-img-section">
          <div className="custContain">
            <div className="title">
              <h3>Related News</h3>
            </div>
            <div className="gallary">
              <div className="gallary-img">
                <img src={newsdetails2} alt="img" />
              </div>
              <div className="gallary-img">
                <img src={newsdetails1} alt="img" />
              </div>
              <div className="gallary-img">
                <img img src={newsdetails3} alt="img" />
              </div>
              <div className="gallary-img">
                <img img src={newsdetails4} alt="img" />
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
}

export default NewsDetails;
