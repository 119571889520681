import React from "react";
import "../HomePage/Index.css";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import gallery1 from "../../Images/Gallery1.jpg";
import gallery2 from "../../Images/Gallery2.jpg";
import gallery3 from "../../Images/Gallery3.jpg";
import gallery4 from "../../Images/Gallery4.jpg";
import gallery5 from "../../Images/Gallery5.jpg";
import gallery6 from "../../Images/Gallery6.jpg";
import gallery7 from "../../Images/Gallery7.jpg";
import gallery8 from "../../Images/Gallery8.jpg";
import gallery9 from "../../Images/Gallery9.jpg";
import gallery10 from "../../Images/Gallery10.jpg";

function LightGallery() {
  return (
    <>
      <section className="gallerySec">
        <div className="custContain">
          <SlideshowLightbox className="galleryimgDiv">
            <img src={gallery1} alt="img" />
            <img src={gallery2} alt="img" />
            <img src={gallery3} alt="img" />
            <img src={gallery4} alt="img" />
            <img src={gallery5} alt="img" />
            <img src={gallery6} alt="img" />
            <img src={gallery7} alt="img" />
            <img src={gallery8} alt="img" />
            <img src={gallery9} alt="img" />
            <img src={gallery10} alt="img" />
            <img src={gallery1} alt="img" />
            <img src={gallery2} alt="img" />
            {/* <img src={gallery3} alt="img" />
            <img src={gallery4} alt="img" />
            <img src={gallery5} alt="img" />
            <img src={gallery6} alt="img" />
            <img src={gallery7} alt="img" />
            <img src={gallery8} alt="img" /> */}
          </SlideshowLightbox>
        </div>
      </section>
    </>
  );
}

export default LightGallery;
