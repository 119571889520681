import React from "react";
import { Link } from "react-router-dom";
import "../About/About.css";

function Index() {
  return (
    <>
      <section className="about_us">
        <div className="custContain">
          <div className="about_top">
            <h3 className="abt_tophead">Who we are</h3>
            <div className="abt_blankdiv"></div>
            <p className="abtquote">
              Knowing where you came from is no less important than knowing
              where you are going
            </p>
            <p className="abt_toppara">- Neil deGrasse Tyson</p>
            <div className="aboutflex">
              <div className="aboutDiv">
                <p>
                  No matter how large a brand is, nationally, they often face a
                  serious challenge when establishing themselves or growing
                  themselves in regional markets. This holds especially true
                  when they wish to enter the eastern market. A strong culture,
                  a very entrenched philosophy, subtle nuances and an approach
                  to life that’s very different from elsewhere define this
                  ‘market’. Which is why, even national brands need a little
                  help from those who understand and regularly engage with the
                  people here. They need someone who writes and composes songs
                  that touch the hearts of every local, but also understand how
                  the world of communication works. They need someone whose art
                  is born of where the sun rises first in this country, but with
                </p>
              </div>
              <div className="aboutDiv">
                <p>
                  national recognition. And they need a team of people who have
                  grown and lived here, embodying the culture they also
                  professionally communicate with. A team that also has its
                  professional experience enriched by having worked and lived
                  across the nation, while absorbing global advertising trends.
                  They need, in other words, SOS Ideas. A truly ‘local’
                  powerhouse with national experience and an international
                  mind-set. There are ‘big’ agencies in Kolkata (and even in
                  Bangladesh), but they are designed to behave nationally. We
                  are designed to work with our feet on the ground, because this
                  is where we – and advertising in India itself – found its
                  feet.
                </p>
              </div>
            </div>
          </div>

          <div className="about_btm">
            <h3 className="abt_btmhead">Our Services</h3>
            <div className="abt_service">
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Brand</span>Consultancy</h4>
                <p className="serPara">
                  Every brand has a personality that makes it unique. Our
                  expertise is in getting to its core and giving it its voice.
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Strategic</span> Brand Ideas</h4>
                <p className="serPara">
                  How must a brand behave so as to reach its intended audience,
                  while achieving its business objectives.
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Advertising & </span>Communication</h4>
                <p className="serPara">
                  How does business objective translate to transformation in the
                  minds of the brand's customers? It’s about both what we say
                  and how we say it.
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Design </span>& Branding</h4>
                <p className="serPara">
                  If clothes maketh the man, it’s no less true for brands. How
                  it looks and presents itself is absolutely crucial to how it’s
                  perceived – and received.
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Integrated </span>Marketing Solutions</h4>
                <p className="serPara">
                  A brand or a product has many avenues to create maximum
                  impact. We excel in finding and taking advantage of the
                  perfect suite of channels.
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Direct </span>Marketing/Mailing</h4>
                <p className="serPara">
                  How does a brand address a mass audience and yet make it
                  appear intimate and personalised? By employing the strategies
                  of door-to-door salespersons.
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Movie </span>Launch Promotions</h4>
                <p className="serPara">
                  Some of the biggest box office earnings are made even before
                  release. It’s because of how immersive and powerful good
                  marketing can be.
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Music, Lyrics & </span>Audio Solutions</h4>
                <p className="serPara">
                  Some tunes just stick in our heads, don’t they? And some
                  tunes, when we think of them, remind us of a brand too. Need
                  one for yours as well?
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Illustration & </span>Calligraphy</h4>
                <p className="serPara">
                  The artistry of writing is an undervalued skill that has
                  defined personalities over the ages. It adds character to the
                  characters of the alphabet, giving words even more worth.
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Web, Digital & </span>Social Media Marketing</h4>
                <p className="serPara">
                  The world is online. Which can only mean two things – one,
                  your brand must be present there as well and two, it must
                  stake a claim and be noticed and loved in this cluttered
                  world.
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead">Viral Marketing</h4>
                <p className="serPara">
                  ‘How do we get viral?’ is a common client question. Content is
                  key. There is no formula other than work that captures the
                  pulse on the ground. Easier said than done – unless you’re
                  talking to the experts.
                </p>
              </div>
              <div className="serviceDiv">
                <h4 className="serHead"><span className="content1">Retail Environment </span>& Exhibition Design</h4>
                <p className="serPara">
                  On ground, customers and partners get to interact with brands
                  and products. If first impressions make lasting ones, first
                  interactions can have an even greater impact.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
