import React from "react";
import { Link } from "react-router-dom";
import "../People/People.css";

// component

import PeopleNew from "../../Component/PeopleNew";
import Trailblazer from "../../Component/Trailblazer";
import ThoughtLeaders from "../../Component/ThoughtLeaders";


function People() {

  return (
    <>

      <PeopleNew />

      <ThoughtLeaders />

      <Trailblazer />

    </>
  );
}

export default People;
