import React from "react";
import { useState } from "react";
import "../HomePage/Index.css";
import { Link } from "react-router-dom";
import campDetails1 from "../../Images/CampDetails1.jpg";
import campDetails2 from "../../Images/CampDetails2.jpg";
import campDetails3 from "../../Images/CampDetails3.jpg";
import campDetails4 from "../../Images/CampDetails4.jpg";
import campDetails5 from "../../Images/CampDetails5.jpg";

function CampDetails() {
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <>
      <section className="campDetails">
        <div className="custContain">
          <div className="campDetmain row">
            <div className="col-sm-7 col-12 campDetDiv">
              <div className="campleft">
                <div className="campVideo">
                  <iframe
                    src="https://www.youtube.com/embed/14JU5prlZmY?si=0zbHmzXat3mLNg9p"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen=""
                    className="video"
                  />
                </div>

                <div className="campDetText">
                  <h5>Bisk Farm Googly campaign with Hrithik Roshan</h5>
                  <p>
                  The TVC shows Hrithik readying for action when suddenly, on taking the first bite of Googly biscuit, something strange happens to him. The Googly bite makes his body twist almost instantly and he involuntarily breaks into dance moves never done before. Then the video finds its way to social media, and fans begin making reels, copying his dance steps as it goes viral.
                  </p>
                  {isShowMore && (
                    <p>
                      Arpan Paul, Executive Chairman, SAJ Food said, “At Bisk Farm, Innovation is in our DNA and we believe that our focus on R&D and product development has been one of the key ingredients to success.  We were regarded as the category disruptors when we launched our Googly range of biscuits and as the name symbolises, the consumers get bowled over by its unique taste. We are extremely excited with our association with the demigod of dance – Hrithik Roshan to bring this brand promise to life through our latest TVC and we are confident that the new campaign will be loved by consumers across the country.”
                    </p>
                  )}

                  <Link onClick={toggleReadMoreLess} className="readmorebtn">
                    {isShowMore ? "Read Less" : "Read More"}
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-5 col-12 campDetDiv">
              <div className="campDetright">
                <div className="camprightTop">
                  <div className="campRight1">
                    <div className="campDetbigimg">
                      <img src={campDetails1} alt="ad" />
                    </div>
                  </div>

                  <div className="campRight2">
                    <div className="campDetsmallimg">
                      <img src={campDetails2} alt="ad" />
                    </div>
                    <div className="campDetsmallimg">
                      <img src={campDetails2} alt="ad" />
                    </div>
                    <div></div>
                  </div>
                </div>

                <div className="camprightBtm">
                  <p>Other Bisk Farm ads</p>
                  <div className="camprightBtmR">
                    <div className="camprightimg">
                      <img src={campDetails3} />
                    </div>

                    <div className="camprightimg">
                      <img src={campDetails4} />
                    </div>

                    <div className="camprightimg">
                      <img src={campDetails5} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CampDetails;
