import React from 'react'
import profilePic from "../../Images/Adrija Kundu.jpg";

function TrailblazersModal(props) {
    const { closeBlazers } = props;
    const { singleData } = props;
    return (
        <>
            <section className='trailblazers_Modal'>
                <div className='trailblazersModal_body'>
                    <button className='closebtn' onClick={() => closeBlazers(false)} >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    {/* <h2 className='heading'>Trailblazers</h2> */}
                    <div className='content_wrap'>
                        <div className='maincontent'>
                            <figure className='profile_pic'>
                                <img src={singleData?.image} className='img-fluid' alt='....' />
                            </figure>
                            <div className='profile_details'>
                                <h4 className='name'>{singleData?.name}</h4>
                                <h6 className='subtitle'>{singleData?.designation}</h6>
                                <p className='detail'>{singleData?.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TrailblazersModal