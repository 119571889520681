import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from "react-router-dom";
import "../View/People/People.css";
import { Users } from '../userArrayList.js/User';
import ThoughtLeadersModal from './Modal/ThoughtLeadersModal';
import toast from 'react-hot-toast';
import HttpClient from "../utils/HttpClient";

function ThoughtLeaders() {

  const [leadersOpen, setLeadersOpen] = useState(false);
  const [thoughtData, setThoughtData] = useState([])
  const [singleData, setSingleData] = useState({});


  const getThoughtLeaders = async () => {
    const res = await HttpClient.requestData("user-view-people-thought", "GET", {})
    if (res && res?.status) {
      setThoughtData(res?.data)
    }
    else {
      toast.error(res?.message)
    }
  }

  const openLeaders = () => {
    setLeadersOpen(true);
  };

  function popTextDetail(e, item) {
    setSingleData(item)
    openLeaders();
  }

  useEffect(() => {
    getThoughtLeaders();
  }, [])

  // console.log("yyyy", thoughtData)
  return (
    <>
      <section className="leaders">
        <div className="custContain">
          <div className="leaders-title">
            <p>Thought Leaders</p>
          </div>
          <div className="leaders-section">
            {thoughtData.map((item) => {
              return (
                <div className="leaders-dtls" key={item.id} onClick={(e) => popTextDetail(e, item)}>

                  {/* <Link 
                  onClick={openLeaders}
                  > */}
                  <div className="leaders-img">
                    <img src={item.image} className='img-fluid' alt="image...." />

                    <div className="dropdown">
                      <button className="dropbtn">
                        <span className="dropText">{item.name}</span>
                        <span className="dropText2">{item.designation}</span>
                      </button>
                      {/* <div className="dropdown-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing
                    elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                    dolore magna aliquam erat volutpat. Ut wisi enim ad
                    minim veniam, quis nostrud exerci tation ullamcorper
                    suscipit lobortis nisl ut aliquip ex ea commodo
                    consequat.
                  </p>
                </div> */}
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {leadersOpen && <ThoughtLeadersModal closeLeaders={setLeadersOpen} singleData={singleData} />}
    </>
  )
}

export default ThoughtLeaders