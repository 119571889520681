import React from 'react';
import "../View/Gateway/Gateway.css";
import gatewayBanner from "../Images/gatewaybanner.jpg";

function GatewayBanner() {
  return (
    <>
     <section className="gatewaybnr">
        <div className="custContain">
          <div className="gatewaybnrimg">
            <div className="gatewayimgdiv">
              <img src={gatewayBanner} alt="img" />
            </div>
            <div className="gatewayText">
              <p>A National Brand’s</p>
              <h3>Gateway to the East</h3>
            </div>
          </div>
          <div className="gatewaybnrtxt">
            <h3>No one knows the East better</h3>
            <div className="gateFlexTxt">
              <p>
              The Eastern market is growing faster than ever before. Joining the booming economy of Bengal are the economies of Orissa, Assam, Jharkhand and Bihar. This rapid growth has given rise to a new class of consumers - affluent, modern and ready to spend. However, to them, national advertising can feel alien – devoid of their languages, local sensibilities and nuances.
              </p>
              <h5>That’s where we come in</h5>
              <p>
                The founders have lived almost their entire lives in this part
                of the world, creating benchmark campaigns for iconic brands for
                over 25 years. Ably supported by an equally rooted team, today,
                the communication responsibilities for brands such as
                Anandabazar Patrika (the largest read Bengali media group), Bisk
                Farm (Eastern India’s largest confectioners), Emami, Centuryply,
                PC Chandra Jewellers, Annapurna, Skipper, among others, also lie
                here. Perhaps why, even national brands such as Amul, Dabur,
                Paytm, Raymond, Bandhan Bank, Coca Cola, Vodafone and Marico
                have chosen to follow suit for their regional communication
                needs.
              </p>
            </div>
            <div className="abtgateBrand">
              <div className="abtgatePoint">
                <p>Unparalleled understanding of Eastern culture</p>
              </div>
              <div className="abtgatePoint">
                <p>World-class, award winning campaigns in East India</p>
              </div>
              <div className="abtgatePoint">
                <p>Roster of powerhouse Eastern and national brands</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default GatewayBanner