import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Images/logo.png";

function Header2() {
  const [headName, setName] = useState("");
  console.log("headName", headName);

  useEffect(() => {
    setName(localStorage.getItem("keyName"));
  }, []);

  return (
    <>
      <section className="header2">
        <div className="custContain">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/" className="Homelink">
                  Home
                </Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {headName}
              </li>
            </ol>
          </nav>
          <div className="headerbody">
            <Link to="/">
              <div className="header2Logo">
                <img src={logo} />
              </div>
            </Link>
            <div className="headerTag">
              <h3 className="headerTop">{headName}</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header2;
