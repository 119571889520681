import React from "react";
import "../Work/Work.css";

// Component

import Workmain from "../../Component/Workmain";
import WorkSidebar from "../../Component/WorkSidebar";

function Work() {

  return (
    <>
      <Workmain />
      <WorkSidebar />
    </>
  );
}

export default Work;
