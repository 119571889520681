import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <section className="footer">
        <div className="custContain">
          <div className="row">
            <div className="col-md-6 col-12 footer-left">
              <div className="footer-section">
                <div className="footer-dtls">
                  <div className="footer-title">
                    <p>Quick Links</p>
                  </div>
                  <div className="footer-conten">
                    <ul>
                      <li>
                        <Link to="/About" className="footerlink">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/People" className="footerlink">
                          People
                        </Link>
                      </li>
                      <li>
                        <Link to="/Work" className="footerlink">
                          Work
                        </Link>
                      </li>
                      <li>
                        <Link to="/News" className="footerlink">
                          News
                        </Link>
                      </li>
                      <li>
                        <Link to="/Gateway" className="footerlink">
                          Gateway To The East
                        </Link>
                      </li>
                      <li>
                        <Link to="/Career" className="footerlink">
                          Careers
                        </Link>
                      </li>
                      <li>
                        <Link to="/Contact" className="footerlink">
                          Contact
                        </Link>
                      </li>
                      <li>
                        <Link to="/Getquote" className="footerlink">
                          Get A Quote
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-sub-conten">
                    <p>Copyright © 2023 SoSIdeas. All Rights Reserved.</p>
                    <p><Link to={'https://greenreeflive.com/'} target="_blank">Maintained by GreenReef</Link></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 footerRight">
              <div className="footer-section" id="footerRightSec">
                <div className="footer-title">
                  <p>Get In Touch</p>
                </div>
                <div className="footer-contenDiv">
                  <p>77A, Raja Basanta Roy Road,</p>
                  <p>Lake Market, Kalighat, Kolkata</p>
                  <p>West Bengal - 700029.</p>
                  <p>033 4603 1697</p>
                </div>
                <div className="footer-sub-contenDiv">
                  <Link to="/">sosideasindia@gmail.com</Link>
                </div>
                <div className="footer-icon">
                  <Link to='https://www.facebook.com/sosideasindia/' target="_blank"><i className="fa-brands fa-facebook-f" /></Link>
                  <Link to='https://in.linkedin.com/in/sos-ideas-50b94a100' target="_blank"><i className="fa-brands fa-linkedin" /></Link>
                  <Link to='https://www.youtube.com/channel/UCqo_aMgdLwu8oOs7kcnaFcw' target="_blank"><i className="fa-brands fa-youtube" /></Link>
                  <Link to='https://instagram.com/sosideasindia?igshid=NzZhOTFlYzFmZQ==' target="_blank"><i className="fa-brands fa-instagram" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
