import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper/modules";

// import abpwedding from "../Images/ABP WEDDINGS.jpeg";
// import Emami from "../Images/EMAMI.jpeg";
// import Boudh from "../Images/BOUDH.jpg";
// import jainlogo from "../Images/Jain Group Logo.jpg";
// import msplogo02 from "../Images/MSP logo-02.jpg";
// import panseeds from "../Images/PAN Seeds.jpg";
// import safedlogo05 from "../Images/Safed logo-05.jpg";
// import sainiklaminates from "../Images/Sainik laminates logo 2022.jpg";
// import sparkle from "../Images/Sparkel logo.jpg";
// import success from "../Images/Success.jpg";
// import sunstone from "../Images/Sunstone-logo.jpg";
// import amritcement from "../Images/AMRIT CEMENT.png";
// import annapurna from "../Images/Annapurna Logo.jpeg";
// import Biskfarm from "../Images/BISK FARM.jpeg";
// import Cars24 from "../Images/Cars 24_B-01.png";
// import century from "../Images/century decorative logo.png";
// import colorsbangla from "../Images/Colors_Bangla.png";
// import dabur from "../Images/DABUR.png";
// import dalmia from "../Images/DALMIA CEMENT.png";
// import EmamiHT from "../Images/EMAMI Healthy&Tasty.png";
// import indiagate from "../Images/indiagate.jpg";
// import Keokarpin from "../Images/Keo Karpin logo.jpeg";
// import rupafront from "../Images/Rupa Frontline logo.png";
// import stylebazaar from "../Images/STYLE BAAZAR.jpeg";
// import vansaar from "../Images/Baidyanath vansaar.jpeg";
// import ambujaneotia from "../Images/AMBUJA NEOTIA.jpg";
// import Amul from "../Images/AMULLogo.jpg";
// import anandabazar from "../Images/ANANDABAZAR PATRIKA.jpg";
// import bandhanbank from "../Images/BANDHAN BANK logo.jpg";
// import bengalgovt from "../Images/BENGAL GOVT.jpg";
// import centuryply from "../Images/CENTURY PLY.jpg";
// import ganeshgrains from "../Images/GANESH GRAINS.jpg";
// import himalaya from "../Images/HIMALAYA.jpg";
// import paytm from "../Images/Paytm.jpg";
// import pcchandra from "../Images/PC CHANDRA JEWELLERS.jpg";
// import primarc from "../Images/PRIMARC.jpg";
// import Raymond from "../Images/Raymond.jpg";
// import Rice from "../Images/RICE GROUP.jpg";
// import shyammetallics from "../Images/SHYAM METALLICS.jpg";
// import Telegraph from "../Images/THE TELEGRAPH.jpg";
import arrowprev from "../Images/ArrowTop.png";
import arrownext from "../Images/ArrowBottom.png";
import HttpClient from "../utils/HttpClient";
import { useNavigate } from "react-router-dom";

function IndexSwiper() {
  const navigate = useNavigate();
  const [swiperRef, setSwiperRef] = useState(null);
  const [picture, setPicture] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // console.log("picturedd", picture)

  const itemsPerSlide = 30;

  // // Calculate the number of slides needed
  // const totalSlides = Math.ceil(picture.length / itemsPerSlide);

  const getpicture = async () => {
    const res = await HttpClient.requestData('user-view-category', "GET", {});
    console.log(res)
    if (res && res?.status) {
      setPicture(res?.data)

    }
  }

  const prevHandler = () => {
    swiperRef.slidePrev();
    setCurrentIndex((prevIndex) =>
      Math.max(prevIndex - itemsPerSlide, 0)
    );
  };

  const nextHandler = () => {
    swiperRef.slideNext();
    // setCurrentIndex((prevIndex) => Math.min(prevIndex + 30, picture.length - 30));
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + itemsPerSlide, picture.length - itemsPerSlide)
    );

  };


  // const loop = [
  //   { id: 1, src: abpwedding, alt: "img" },
  //   { id: 2, src: Emami, alt: "img" },
  //   { id: 3, src: Boudh, alt: "img" },
  //   { id: 4, src: jainlogo, alt: "img" },
  //   { id: 5, src: msplogo02, alt: "img" },
  //   { id: 6, src: panseeds, alt: "img" },
  //   { id: 7, src: safedlogo05, alt: "img" },
  //   { id: 8, src: sainiklaminates, alt: "img" },
  //   { id: 9, src: sparkle, alt: "img" },
  //   { id: 10, src: success, alt: "img" },
  //   { id: 11, src: sunstone, alt: "img" },
  //   { id: 12, src: amritcement, alt: "img" },
  //   { id: 13, src: annapurna, alt: "img" },
  //   { id: 14, src: Biskfarm, alt: "img" },
  //   { id: 15, src: Cars24, alt: "img" },
  //   { id: 16, src: century, alt: "img" },
  //   { id: 17, src: colorsbangla, alt: "img" },
  //   { id: 18, src: dabur, alt: "img" },
  //   { id: 19, src: dalmia, alt: "img" },
  //   { id: 20, src: EmamiHT, alt: "img" },
  //   { id: 21, src: indiagate, alt: "img" },
  //   { id: 22, src: Keokarpin, alt: "img" },
  //   { id: 23, src: rupafront, alt: "img" },
  //   { id: 24, src: stylebazaar, alt: "img" },
  //   { id: 25, src: vansaar, alt: "img" },
  //   { id: 26, src: ambujaneotia, alt: "img" },
  //   { id: 27, src: Amul, alt: "img" },
  //   { id: 28, src: anandabazar, alt: "img" },
  //   { id: 29, src: bandhanbank, alt: "img" },
  //   { id: 30, src: bengalgovt, alt: "img" },
  // ];

  // const loop2 = [
  //   { id: 31, src: ganeshgrains, alt: "img" },
  //   { id: 32, src: himalaya, alt: "img" },
  //   { id: 33, src: paytm, alt: "img" },
  //   { id: 34, src: pcchandra, alt: "img" },
  //   { id: 35, src: primarc, alt: "img" },
  //   { id: 36, src: Raymond, alt: "img" },
  //   { id: 37, src: Rice, alt: "img" },
  //   { id: 38, src: shyammetallics, alt: "img" },
  //   { id: 39, src: Telegraph, alt: "img" },
  //   { id: 40, src: centuryply, alt: "img" },
  // ]

  useEffect(() => {
    getpicture()
  }, []);


  return (
    <>
      <section className="indexClients">
        <div className="custContain">
          <h3 className="indexhead">Our Clients</h3>
          {/* <button onClick={prevHandler}>Prev</button> */}
          <div className="arrowprev" onClick={prevHandler}>
            <img src={arrowprev} />
          </div>
          <Swiper
            slidesPerView={3}
            direction={"vertical"}
            loop={true}
            pagination={{
              clickable: false,
            }}
            navigation={false}
            modules={[Navigation]}
            onSwiper={(swiper) => setSwiperRef(swiper)}
            className="mySwiper"
          >
            <SwiperSlide>
              <div>
                <div className="clientRow row">
                  {picture.slice(currentIndex, currentIndex + 30).map((image, i) => {
                    return (
                      <div className="clientCol col-sm-2 col-3" key={i}>
                        <div className="clientwrap " style={{ cursor: "pointer" }} onClick={() => navigate("/Work", { state: { brandId: image?._id } })}>
                          <img src={image.logoUrl} />
                          {/* {image.name} */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div>
                <div className="clientRow row">
                  {picture.map((image,i) => {
                    return (
                      <div className="clientCol col-sm-2 col-3" key={i}>
                        <div className="clientwrap">
                          <img src={image.logoUrl} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </SwiperSlide> */}

          </Swiper>
          {/* <button onClick={nextHandler}>Next</button> */}
          <div className="arrownext" onClick={nextHandler}>
            <img src={arrownext} />
          </div>
        </div>
      </section>
    </>
  );
}

export default IndexSwiper;
