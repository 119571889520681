import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import "../HomePage/Index.css";
import CampDetails3 from "../../Images/CampDetails3.jpg";
import CampDetails4 from "../../Images/CampDetails4.jpg";
import CampDetails5 from "../../Images/CampDetails5.jpg";
import print1 from "../../Images/print1.jpg";
import print2 from "../../Images/print2.jpg";
import printbig from "../../Images/printBig.jpg";

function CampPoster() {

  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <>
      <section className="CampPoster">
        <div className="custContain">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12">
              <div className="camp-imgDiv">
                <img src={printbig} alt="poster" />
              </div>
            </div>

            <div className=" col-xl-8 col-lg-8 col-md-7 col-sm-6 col-12 campdetailsdiv">
              <div className="poster-dtls">
                <h3>Bisk Farm Spicy</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Provident, explicabo animi beatae voluptates deleniti hic
                  obcaecati nemo odio nostrum sunt nisi consequatur natus dolore
                  laboriosam libero tempora suscipit voluptas sapiente. Lorem
                  ipsum dolor sit, amet consectetur adipisicing elit. Ratione
                  pariatur consectetur eveniet fugit exercitationem nulla enim
                  quo culpa quos veritatis esse, ipsa possimus tempore numquam
                  asperiores sint omnis harum laborum. Lorem ipsum dolor sit
                  amet, consectetur adipisicing elit.
                </p>

                {isShowMore && (
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Provident, explicabo animi beatae voluptates deleniti hic
                    obcaecati nemo odio nostrum sunt nisi consequatur natus
                    dolore laboriosam libero tempora suscipit voluptas sapiente.
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Ratione pariatur consectetur eveniet fugit exercitationem
                    nulla enim quo culpa quos veritatis esse, ipsa possimus
                    tempore numquam asperiores sint omnis harum laborum.Lorem
                    ipsum dolor sit amet, consectetur adipisicing elit.
                    Provident, explicabo animi beatae voluptates deleniti hic
                    obcaecati nemo odio nostrum sunt nisi consequatur natus
                    dolore laboriosam libero tempora suscipit voluptas sapiente.
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Ratione pariatur consectetur eveniet fugit exercitationem
                    nulla enim quo culpa quos veritatis esse, ipsa possimus
                    tempore numquam asperiores sint omnis harum laborum.
                  </p>
                )}
                <Link onClick={toggleReadMoreLess} className="readmorebtn">
                  {isShowMore ? "Read Less" : "Read More"}
                </Link>
              </div>

              <div className="posterBtm">
                <div className="otheradtop">
                  <p>Other Bisk Farm ads</p>
                  <div className="otherAdDiv">
                    <div className="otherAd">
                      <img src={CampDetails3} alt="img" />
                    </div>

                    <div className="otherAd">
                      <img src={CampDetails4} alt="img" />
                    </div>

                    <div className="otherAd">
                      <img src={CampDetails5} alt="img" />
                    </div>
                  </div>
                </div>

                <div className="otheradBtm">
                  <p>Other Bisk Farm Spicy ads</p>
                  <div className="otherAdDiv">
                    <div className="otherAd">
                      <img src={print1} alt="img" />
                    </div>

                    <div className="otherAd">
                      <img src={print2} alt="img" />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="campPosterBtm">
                <div className="poster-adDiv">
                  <p>Other Bisk Farm ads</p>
                  <div className="poster-ad">
                    <div className="poster-add-img">
                      <img src={CampDetails3} alt="poster-ad" />
                    </div>
                    <div className="poster-add-img">
                      <img src={CampDetails4} alt="poster-ad" />
                    </div>
                    <div className="poster-add-img">
                      <img src={CampDetails5} alt="poster-ad" />
                    </div>
                  </div>
                </div>
                <div className="sub-poster-adDiv">
                  <p>Other Bisk Farm Spicy ads</p>
                  <div className="sub-poster-ad">
                    <div className="sub-poster-img">
                      <img src={print1} alt="poster-ad" />
                    </div>
                    <div className="sub-poster-add-img">
                      <img src={print2} alt="poster-ad" />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CampPoster;
