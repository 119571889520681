import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../View/Gateway/Gateway.css";
import index1 from "../Images/index1.jpg";
import index2 from "../Images/index2.jpg";
import index3 from "../Images/index3.jpg";
import index4 from "../Images/index4.jpg";
import index5 from "../Images/index5.jpg";
import index6 from "../Images/index6.jpg";
import index7 from "../Images/index7.jpg";
import index8 from "../Images/index8.jpg";
import index9 from "../Images/index9.png";
import index10 from "../Images/index10.jpg";
import index11 from "../Images/index11.jpg";
import HttpClient from '../utils/HttpClient';
import { toast } from 'react-hot-toast';

function GatewayCaseStudy() {
    const [caseStudyData, setCaseStudydata] = useState([])
    const [loading, setLoading] = useState(false);

    const casestudy = [

        { id: 1, src: index5, alt: "img", name: "Amul" },
        // { id: 2, src: index1, alt: "img", name: "Cars 24" },
        // { id: 3, src: index2, alt: "img", name: "Rupa" },
        // { id: 4, src: index3, alt: "img", name: "Bisk Farm" },
        // { id: 5, src: index4, alt: "img", name: "Durand Cup" },
        // { id: 6, src: index12, alt: "img", name: "Movie Poster" },
        // { id: 7, src: index6, alt: "img", name: "Rollick" },
        // { id: 8, src: index7, alt: "img", name: "P.C. Chandra" },
        // { id: 9, src: index8, alt: "img", name: "Myntra" },
        // { id: 10, src: index9, alt: "img", name: "Raymond" },
        // { id: 11, src: index10, alt: "img", name: "Keo Karpin" },
        // { id: 12, src: index11, alt: "img", name: "Success" },
    ];

    //get all case study
    const getCaseStudyData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("user-view-case-study", "GET", {})
        if (res && res.status) {
            setCaseStudydata(res?.data)
            setLoading(false);
        } else {
            toast.error(res?.message)
            setLoading(false);
        }
    }

    useEffect(() => {
        getCaseStudyData()
    }, [])

    return (
        <>
            <section className="caseStudynew">
                <div className="custContain">
                    <div className="casetitle">
                        <p>Case Study</p>
                    </div>
                    <div className="row gatecaseSmall">
                        {caseStudyData.map((item, index) => {
                            return (
                                <div className="col-lg-2 col-sm-4 col-6 casecol" key={index}>
                                    <div className="caseStudydiv">
                                        <Link to={`/case-study-details/${item?._id}`}>
                                            <div className="caseimg">
                                                <img key={item.id} src={item.image[0] ? item.image[0] : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} />
                                            </div>
                                            <div className="caseText">
                                                <p>{item.title}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default GatewayCaseStudy