import React, { useEffect, useState } from "react";
import "../News/News.css";
// import foxaward from "../../Images/Fox Gloves.jpg"
// import annapurna from "../../Images/AnnapurnaNews.jpeg";
// import merlin from "../../Images/MerlinNews.jpeg";
// import bandhan from "../../Images/bandhanNews.png";
// import mspsteel from "../../Images/MSP Steel symphony.png";
// import msphuman from "../../Images/MSP Steel human.jpeg";
// import mspholi from "../../Images/MSP Holi heros.jpeg";
// import mspwomen from "../../Images/MSP Women.jpeg";
// import amul from "../../Images/AmulNews.jpeg";
// import telegraph from "../../Images/TelegraphNews.jpeg";
// import heylo from "../../Images/BiskHeyloNews.png";
// import indiaah from "../../Images/IndiaahNews.jpeg";
// import bonbon from "../../Images/BonBonNews.jpeg";
// import rollickdurga from "../../Images/Rollickdurganews.jpeg";
// import pcchandra from "../../Images/GoldenWeaponNews.jpeg";
// import anandamela from "../../Images/AnandamelaNews.jpeg";
// import virus1 from "../../Images/Stop the virus1.jpeg";
// import virus2 from "../../Images/Stop the virus2.jpeg";
// import success1 from "../../Images/Success1.jpeg";
// import success2 from "../../Images/Success2.jpeg";
// import success3 from "../../Images/Success3.jpeg";
// import success4 from "../../Images/Success4.jpeg";
// import durand from "../../Images/DurandNews.jpeg";
// import desh from "../../Images/Desh News.jpeg";
// import powerprint from "../../Images/Power of printNews.jpeg";
// import purnamskin from "../../Images/Purnam Skin News.jpeg";
// import purnamtattoo from "../../Images/Purnam Tattoo News.png";
// import glamour1 from "../../Images/Glamour World1.jpeg";
// import glamour2 from "../../Images/Glamour World2.jpeg";
// import croma from "../../Images/Croma News.jpeg";
// import magiklights from "../../Images/Magik Lights News.jpeg";
// import concern from "../../Images/Concern Kolkata News.jpeg";
// import rocket from "../../Images/Rocket News.jpeg";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";

function News() {

  const [newsData, setNewsData] = useState([]);

  console.log("newsData", newsData)

  // const newsList = [
  //   {
  //     id: 1,
  //     src: bandhan,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/stories-of-india",
  //   },
  //   {
  //     id: 2,
  //     src: mspsteel,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/the-symphony-of-steel",
  //   },
  //   {
  //     id: 3,
  //     src: msphuman,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/human-structures",
  //   },
  //   {
  //     id: 4,
  //     src: mspholi,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/holi-heroes",
  //   },
  //   {
  //     id: 5,
  //     src: mspwomen,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/women-made-social-experiment",
  //   },
  //   {
  //     id: 6,
  //     src: amul,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/daddy-kool-d188bec8-124e-41f7-a213-02b5e05336e6",
  //   },
  //   {
  //     id: 7,
  //     src: telegraph,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/my-kolkata",
  //   },
  //   {
  //     id: 8,
  //     src: heylo,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/start-a-conversation-with-heylo",
  //   },
  //   {
  //     id: 9,
  //     src: indiaah,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/tempting-taste",
  //   },
  //   {
  //     id: 10,
  //     src: bonbon,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/head-spin",
  //   },
  //   {
  //     id: 11,
  //     src: rollickdurga,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/rollick-durga-pujo-16ca3121-091b-47c5-aeee-b86d45aaa9be",
  //   },
  //   {
  //     id: 12,
  //     src: pcchandra,
  //     alt: "img",
  //     link: "https://adsofbrands.net/en/ads/purnam-medicare-purnams-skin-tightening-solutions-3/10750",
  //   },
  //   {
  //     id: 13,
  //     src: anandamela,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/vahans-of-imagination",
  //   },
  //   {
  //     id: 14,
  //     src: virus1,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/goviraltostopthevirus",
  //   },
  //   {
  //     id: 15,
  //     src: virus2,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/goviraltostopthevirus-fa950687-a988-41af-8763-d18c72ab644c",
  //   },
  //   {
  //     id: 16,
  //     src: success1,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/spread-the-warmth",
  //   },
  //   {
  //     id: 17,
  //     src: success2,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/enjoy-summer-in-style",
  //   },
  //   {
  //     id: 18,
  //     src: success3,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/weave-the-festival-in-style",
  //   },
  //   {
  //     id: 19,
  //     src: success4,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/spring-summer-collection",
  //   },
  //   {
  //     id: 20,
  //     src: durand,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/durand-cup-2019",
  //   },
  //   {
  //     id: 20,
  //     src: annapurna,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/shudh-desi-laughter",
  //   },
  //   {
  //     id: 22,
  //     src: desh,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/desh-festive-campaign",
  //   },
  //   {
  //     id: 23,
  //     src: powerprint,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/let-s-eat-into-the-past",
  //   },
  //   {
  //     id: 24,
  //     src: purnamskin,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/purnam-s-skin-tightening-solutions",
  //   },
  //   {
  //     id: 25,
  //     src: purnamtattoo,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/tattoo-butterfly",
  //   },
  //   {
  //     id: 26,
  //     src: glamour1,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/too-tiny-to-be-game",
  //   },
  //   {
  //     id: 27,
  //     src: glamour2,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/train-robbery",
  //   },
  //   {
  //     id: 28,
  //     src: croma,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/the-bird",
  //   },
  //   {
  //     id: 29,
  //     src: magiklights,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/tortoise",
  //   },
  //   {
  //     id: 30,
  //     src: concern,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/penguin-d6f849ad-d9cf-4920-8a66-d454e063432d",
  //   },
  //   {
  //     id: 31,
  //     src: rocket,
  //     alt: "img",
  //     link: "https://www.adsoftheworld.com/campaigns/long-and-strong",
  //   },
  // ];


  const getNews = async () => {
    const res = await HttpClient.requestData("user-view-news", "GET");
    if (res && res?.status) {
      setNewsData(res?.data);
    } else {

    }
  }

  useEffect(() => {
    getNews();
  }, [])

  return (
    <>
      <section className="newspart">
        <div className="custContain">
          <div className="newsMain">
            <div className="bignewsrow">

              <div className="bignewscol">
                <Link to={newsData?.[0]?.link} className="Newsdetails" target="_blank">
                  <div className="bignewsimg">
                    <img src={newsData?.[0]?.image} alt="img" />
                  </div>
                </Link>
              </div>

              <div className="bignewscol">
                <Link to={newsData?.[1]?.link} className="Newsdetails" target="_blank">
                  <div className="bignewsimg">
                    <img src={newsData?.[1]?.image} alt="img" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="smallnewsrow">

              {newsData?.slice(2)?.map((item) => {
                return (
                  <div className="Newscol">
                    <Link to={item.link} className="Newsdetails" target="_blank">
                      <div className="newsImg">
                        <img key={item.id} src={item.image} alt="img" />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default News;
