import React, { useState } from "react";
import "../HomePage/Index.css";
import Rupamain from "../../Images/RUPA.jpg";
import Rupa4 from "../../Images/Rupa4.jpg";
import Rupa5 from "../../Images/Rupa5.jpg";
import Rupa6 from "../../Images/Rupa6.jpg";
import ShowSliderRupa from "../../Component/ShowSliderRupa";

const data = [
  {
    title:'Rupa',
    image: Rupamain,
    link: Rupamain,
  },
  {
    title:'Rupa',
    image:Rupa4,
    link:Rupa4,
  },
  {
    title:'Rupa',
    image:Rupa5,
    link:Rupa5,
  },
  {
    title:'Rupa',
    image:Rupa6,
    link:Rupa6,
  }
]
function Rupa() {
  const [linkurl, setLinkUrl] = useState(Rupamain)

  return (
    <>
      <section className="Indexshowcase">
        <div className="custContain">
          <div className="showcasemain">
            <div className="showcaseleft">
              <div className="showcaseimg">
                {/* <iframe
                  src={linkurl}
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                  className="showvideo"
                /> */}
                <img src={linkurl} />
              </div>
              <div className="showimgDiv">

                {
                  data?.map((it,ind)=>{
                    return(
                      <div className="showimgdivFlex"
                      key={ind}
                      onClick={()=>setLinkUrl(it.link)}
                    >
                      <div className="showimgwrap">
                        <img src={it.image} alt="img" />
                      </div>
                      <div className="showText">
                        <p>{it?.title}</p>
                      </div>
                    </div>
                    )
                  })
                }
              </div>
            </div>

            <div className="showcaseright">
              <h3>
                RUPA Frontline
              </h3>
              <p>
              In its most recent outdoor campaign featuring showman Ranveer Singh, Rupa Frontline wanted to remain faithful to its tagline — 'Yeh style ka mamla hai'. Capitalising on Ranveer Singh's effervescent personality and unconventional sartorial sense, we crafted a vibrant and quirky campaign that effortlessly grabbed everyone's attention.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <ShowSliderRupa /> */}
    </>
  );
}

export default Rupa;
