import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../HomePage/Index.css";
import WorkInnerSlider from '../../Component/WorkInnerSlider';
// import aaroananda1 from "../../Images/Aaro Ananda0.jpg";
// import aaroananda2 from "../../Images/Aaro Ananda1.jpeg";
// import aaroananda3 from "../../Images/Aaro Ananda2.jpeg";
// import aaroananda4 from "../../Images/Aaro Anondo 4.jpg";
// import aaroananda from "../../Images/Aaro Anondo.jpg";
import HttpClient from "../../utils/HttpClient";
import ReactPlayer from "react-player";
import CustomLoader from "../../CustomComponents/loader/CustomLoader";
import WorkModal from "../../Component/Modal/WorkModal";

// const data = [
//   {
//     title: 'Aaro Anondo',
//     image: aaroananda,
//     link: "https://www.youtube.com/embed/NO8CXYNBLEQ?si=4jcGp3Mo0GghNrR-",
//     type: 'video'
//   },
//   {
//     title: 'Aaro Anondo',
//     image: aaroananda1,
//     link: aaroananda1,
//     type: 'img'
//   },
//   {
//     title: 'Aaro Anondo',
//     image: aaroananda2,
//     link: aaroananda2,
//     type: 'img'
//   },
//   {
//     title: 'Aaro Anondo',
//     image: aaroananda3,
//     link: aaroananda3,
//     type: 'img'
//   },
//   {
//     title: 'Aaro Anondo',
//     image: aaroananda4,
//     link: aaroananda4,
//     type: 'img'
//   },
// ]

function WorkInner() {

  const [WorkModalOpen, setWorkModalOpen] = useState(false);
  const handleWorkClick = () => {
    setWorkModalOpen(true);
  };

  const params = useParams();

  const [linkurl, setLinkUrl] = useState("");
  const [type, setType] = useState('')

  const [singleWork, setSingleData] = useState({});
  const [loading, setLoading] = useState(false);

  console.log("singleWorkss", singleWork)

  // fetch singledata
  const getSingleWorkDetails = async () => {
    setLoading(true);
    const res = await HttpClient.requestData("view-single-work/" + params.id, "GET");
    // console.log("resSingleWork", res);
    if (res && res?.status) {
      setSingleData(res?.data[0]);
      setLoading(false);
      if (!res?.data[0]?.video) {
        setType("img");
        setLinkUrl(res?.data[0]?.image[0])
      } else {
        setType("")
      }

      if (!res?.data[0]?.image[0]) {
        setLinkUrl("https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png")
      }
    } else {
      setLoading(false);
    }

  }

  // const handleimage = (e, it) => {

  // }

  useEffect(() => {
    getSingleWorkDetails()
  }, [params?.id]);

  console.log(linkurl, "linkurl")

  return (
    <>
      <CustomLoader loading={loading} />

      <section className="Indexshowcase">
        <div className="custContain">
          <div className="showcasemain">

            {/* video section */}
            <div className="showcaseleft">
              <div className="showcaseimg">
                {
                  type == 'img' ?
                    <img src={linkurl} className="img-fluid" alt="" onClick={handleWorkClick} />
                    :
                    <ReactPlayer
                      className='showvideo'
                      controls
                      width='42rem'
                      height='24rem'
                      playing={type === "video" ? true : false}
                      url={singleWork?.video}
                      onBuffer={() => {
                        console.log("object")
                        setType("video")
                        setWorkModalOpen(true)
                      }}

                      config={{
                        youtube: {
                          playerVars: { showinfo: 1 }
                        },
                        facebook: {
                          appId: '12345'
                        },
                      }}

                    />
                }
              </div>


              {/*  */}
              <div className="showimgDiv">

                {/* video */}
                <div>
                  {
                    singleWork?.video &&
                    <ReactPlayer
                      className='showvideo'
                      // controls
                      width='10rem'
                      height='7.5rem'
                      url={singleWork?.video}
                      playing={false}
                      onBuffer={() => {
                        console.log("object")
                        setType("video")
                        setWorkModalOpen(true)
                      }}

                    />
                  }
                </div>

                {/* images */}
                {
                  singleWork?.image?.map((it, ind) => {
                    return (
                      <div className="showimgdivFlex"
                        key={ind}
                        onClick={() => {
                          //  (e)=>handleimage(e,it)
                          setLinkUrl(it)
                          setType("img")
                        }}
                      >
                        <div className="showimgwrap" style={{ cursor: "pointer" }}>
                          <img src={it} alt="img" onClick={handleWorkClick} />
                        </div>
                        <div className="showText" onClick={handleWorkClick}>
                          <p>{singleWork?.title}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>


            {/* title and sbtitle */}
            <div className="showcaseright">
              <h3>
                {/* Gij gij kore jaha Bangalir Mogoje */}
                {singleWork?.subTitle}
              </h3>
              <p>
                {singleWork?.description}
                {/* Aro Ananda launched an eponymous app to promote and uphold the rich culture and tradition of Bengal. Packed with content that represents what Bengal stands for and what the community is proud of, all in one place. The campaign therefore brings out the essence of a very diverse audience congregating on the same app for its equally diverse content. */}
              </p>
            </div>

          </div>
        </div>
      </section>

      {/* slider */}
      <WorkInnerSlider categoryId={singleWork?.catID} singleWork={singleWork} />

      {WorkModalOpen && <WorkModal closeWorkmodal={setWorkModalOpen} singleWork={singleWork} linkurl={linkurl} type={type} />}
    </>
  )
}

export default WorkInner