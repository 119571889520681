import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import sidebarLogo from "../Images/sidebarLogo.png";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "../View/Work/Work.css";


function WorkSidebar() {

    const location = useLocation();
    console.log("location", location.state);


    const wrkResSideOpen = () => {
        document.querySelector(".workPageSidebar").style.cssText +=
            "transform: translate(0, 0);";
    };

    const wrkResSideClose = () => {
        document.querySelector(".workPageSidebar").style.cssText +=
            "transform: translate(-350px, 0);";
    };



    // console.log(brandData,'gwfghvsgh')
    return (
        <>
            <div className="workPageSidebar">
                <div className="wrkSideHeadDiv">
                    <Link to="/">
                        <figure className="wrkSideFig">
                            <img src={sidebarLogo} alt="..." />
                        </figure>
                    </Link>
                    <button className="wrkSideClose" onClick={wrkResSideClose}>
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="wrkSideMenuDiv">
                    <Accordion className="wrkPageResSidebar">
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span className="">Brand</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>

                                <ul className="wrkResSideUl">
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            ABP
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Bandhan Bank
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Bisk Farm
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Dabur
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Emami
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Keo Karpin
                                        </Link>
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span className="">Industry</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul className="wrkResSideUl">
                                    <li className="wrkResSideLi">
                                        <div>
                                            <Link to="/" className="wrkResSideLiAn">
                                                Automobiles
                                            </Link>
                                        </div>
                                        <div className="">
                                            <Link to="/" className="wrkResSideLiAn">
                                                Cars 24
                                            </Link>
                                        </div>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Beverages
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Decor
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Fashion & Jewellery
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Finance
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            FMCG
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Hardware / Construction
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Health
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Movies
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Portals
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Publications
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Retail
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Agriculture
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Realty
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Services
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            TV
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Tournament
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Education
                                        </Link>
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span className="">Language</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul className="wrkResSideUl">
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            English
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Bengali
                                        </Link>
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span className="">Media</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul className="wrkResSideUl">
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Video
                                        </Link>
                                    </li>
                                    <li className="wrkResSideLi">
                                        <Link to="/" className="wrkResSideLiAn">
                                            Static
                                        </Link>
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </>
    )
}

export default WorkSidebar