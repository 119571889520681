import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Images/logo.png";
import sidebarLogo from "../Images/sidebarLogo.png";
import { useState } from "react";
import HttpClient from "../utils/HttpClient";
// import CustomLoaderLine from "../CustomComponents/loader/CustomLoaderLine";

function Header() {
  const [searchShow, setSearchShow] = useState(false)
  const [searchShowRes, setSearchShowRes] = useState(false)
  const navigate = useNavigate();

  const [searchList, setSearchList] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchLoading, setSrarchLoading] = useState(false);

  // console.log("serchList", searchList.length)


  // get search list fetch
  const getSearchList = async () => {
    const sendData = {
      key_brand_indus_media: searchName
    }
    setSrarchLoading(true);
    const res = await HttpClient.requestData("user-all-search", "POST", sendData)
    // console.log("resdd", res)
    if (res && res?.status) {
      setSearchList([...res?.data?.brand, ...res?.data?.industry, ...res?.data?.media]);
      setSrarchLoading(false);
    }
    else {
      setSrarchLoading(false);
    }
  }

  // const showSearch = () => {
  //   document.querySelector(".searInpDiv").classList.remove("searInpDisplayDiv");
  // };

  const closeSearch = () => {
    document.querySelector(".searInpDiv").classList.add("searInpDisplayDiv");
  };

  const openSideBar = () => {
    document.querySelector(".sideBarMenu").style.cssText +=
      "transform: translate(0, 0);";
  };

  const closeSideBar = () => {
    document.querySelector(".sideBarMenu").style.cssText +=
      "transform: translate(-350px, 0);";
  };


  useEffect(() => {
    if (searchName) {
      const timer = setTimeout(() => {
        getSearchList();
      }, 500)
      return () => clearTimeout(timer)
    } else {
      setSearchList([])
    }
  }, [searchName])

  return (
    <>
      <section className="header">
        <div className="custContain">
          <div className="custSubCont">
            <figure className="headLogo">
              <Link to="/">
                <img src={logo} />
              </Link>
            </figure>
            <div className="optionsDiv">
              <ul className="headUL">
                <li
                  className="headLI"
                  onClick={() => localStorage.setItem("keyName", "About Us")}
                >
                  <Link to="/About" className="headLIAn">
                    ABOUT
                  </Link>
                </li>
                <li
                  className="headLI"
                  onClick={() => localStorage.setItem("keyName", "People")}
                >
                  <Link to="/People" className="headLIAn">
                    PEOPLE
                  </Link>
                </li>
                <li
                  className="headLI"
                  onClick={() => localStorage.setItem("keyName", "Work")}
                >
                  <Link to="/Work" className="headLIAn">
                    {/* <Link className="headLIAn"> */}
                    WORK
                  </Link>
                </li>
                <li
                  className="headLI"
                  onClick={() => localStorage.setItem("keyName", "News")}
                >
                  <Link to="/News" className="headLIAn">
                    NEWS
                  </Link>
                </li>
                <li
                  className="headLI"
                  onClick={() => localStorage.setItem("keyName", "Careers")}
                >
                  <Link to="/Career" className="headLIAn">
                    CAREERS
                  </Link>
                </li>
                <li
                  className="headLI"
                  onClick={() => localStorage.setItem("keyName", "Contact")}
                >
                  <Link to="/Contact" className="headLIAn">
                    CONTACT
                  </Link>
                </li>
                <li
                  className="headLI"
                  onClick={() => localStorage.setItem("keyName", "Get a Quote")}
                >
                  <Link to="/Getquote" className="headLIAn">
                    GET A QUOTE
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className="gateDiv"
              onClick={() => localStorage.setItem("keyName", "Gateway")}
            >
              <Link to="/Gateway" className="gtwaySpanAn">
                GATEWAY TO THE EAST
              </Link>
              <div className="headIconDivs">
                <Link to='https://www.facebook.com/sosideasindia/' className="headIconAn" target="_blank">
                  <i class="fa-brands fa-facebook-f"></i>
                </Link>
                <Link to='https://in.linkedin.com/in/sos-ideas-50b94a100' className="headIconAn" target="_blank">
                  <i class="fa-brands fa-linkedin-in"></i>
                </Link>
                <Link to='https://www.youtube.com/channel/UCqo_aMgdLwu8oOs7kcnaFcw' className="headIconAn" target="_blank">
                  <i class="fa-brands fa-youtube"></i>
                </Link>
                <Link to='https://instagram.com/sosideasindia?igshid=NzZhOTFlYzFmZQ==' className="headIconAn" target="_blank">
                  <i class="fa-brands fa-instagram"></i>
                </Link>
              </div>

              {/* search  */}
              {searchShow && <div className="newSearch">
                <div className="newSerDiv">
                  <input
                    type="search"
                    value={searchName}
                    placeholder="Search here..."
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                </div>

                {
                  searchName &&
                  <div className="searchDrop">
                    <ul>
                      {
                        searchList?.map((item, i) =>
                          <li
                            key={i}
                            onClick={() => {
                              navigate(`/search-work/${item?._id}/${item?.fieldName}/${item?.name}`);
                              setSearchName("")
                            }}
                          >
                            <div className="searchdropLink">{item?.name}</div>
                          </li>
                        )
                      }

                      {
                        !searchLoading && searchList?.length === 0 &&
                        <div className="d-flex justify-content-center">
                          No Data Found...
                        </div>
                      }

                      {
                        // searchLoading && <CustomLoaderLine />
                      }

                      {/* <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li> */}
                    </ul>
                  </div>
                }
              </div>}

            </div>

            <div className="searchGlass" id="serachglassweb">
              <button className="serachBtn" onClick={() => setSearchShow((prev) => !prev)}>
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
              <button className="toggleBtn" onClick={openSideBar}>
                <i class="fa-solid fa-bars-staggered"></i>
              </button>
            </div>

            {searchShowRes && <div className="searchbarRes">
              <div className="newSerDivRes">
                <input type="search" value={searchName}
                  placeholder="Search here..."
                  onChange={(e) => setSearchName(e.target.value)} />
              </div>

              <div className="searchDropRes">
                <ul>
                  {
                    searchList?.map((item, i) =>
                      <li
                        key={i}
                        onClick={() => {
                          navigate(`/search-work/${item?._id}/${item?.fieldName}/${item?.name}`);
                          setSearchName("")
                        }}
                      >
                        <div className="searchdropLink">{item?.name}</div>
                      </li>
                    )
                  }

                  {
                    !searchLoading && searchList?.length === 0 &&
                    <div className="d-flex justify-content-center">
                      No Data Found...
                    </div>
                  }

                  {
                    // searchLoading && <CustomLoaderLine />
                  }

                  {/* <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li>
                    <li><Link className="searchdropLink">Lorem Ipsum</Link></li> */}
                </ul>
              </div>

            </div>}

            <div className="searchGlass" id="serachglassRes">
              <button className="serachBtn" onClick={() => setSearchShowRes((prev) => !prev)}>
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
              <button className="toggleBtn" onClick={openSideBar}>
                <i class="fa-solid fa-bars-staggered"></i>
              </button>
            </div>

          </div>
          <div className="searInpDiv searInpDisplayDiv">
            <div className="clsSearDiv">
              <button className="closeSearchBtn" onClick={closeSearch}>
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="innerSerDiv">
              <input type="search" placeholder="Search here..." />
              <button className="innerSerBtn">Search</button>

            </div>
          </div>

          <div className="sideBarMenu">
            <div className="closeSideBar">
              <figure className="sideBarFig">
                <Link to="/">
                  <img src={sidebarLogo} />
                </Link>
              </figure>
              <button className="closeSideBtn" onClick={closeSideBar}>
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <ul className="resUL">
              <li
                className="resSideLi"
                onClick={() => localStorage.setItem("keyName", "About Us")}
              >
                <Link
                  to="/About"
                  className="resSideLiAn"
                  onClick={closeSideBar}
                >
                  ABOUT
                </Link>
              </li>
              <li
                className="resSideLi"
                onClick={() => localStorage.setItem("keyName", "People")}
              >
                <Link
                  to="/People"
                  className="resSideLiAn"
                  onClick={closeSideBar}
                >
                  PEOPLE
                </Link>
              </li>
              <li
                className="resSideLi"
                onClick={() => localStorage.setItem("keyName", "Work")}
              >
                <Link to="/Work" className="resSideLiAn" onClick={closeSideBar}>
                  WORK
                </Link>
              </li>
              <li
                className="resSideLi"
                onClick={() => localStorage.setItem("keyName", "News")}
              >
                <Link to="/News" className="resSideLiAn" onClick={closeSideBar}>
                  NEWS
                </Link>
              </li>
              <li
                className="resSideLi"
                onClick={() => localStorage.setItem("keyName", "Careers")}
              >
                <Link
                  to="/Career"
                  className="resSideLiAn"
                  onClick={closeSideBar}
                >
                  CAREERS
                </Link>
              </li>
              <li
                className="resSideLi"
                onClick={() => localStorage.setItem("keyName", "Contact")}
              >
                <Link
                  to="/Contact"
                  className="resSideLiAn"
                  onClick={closeSideBar}
                >
                  CONTACT
                </Link>
              </li>
              <li
                className="resSideLi"
                onClick={() => localStorage.setItem("keyName", "Get a Quote")}
              >
                <Link
                  to="/Getquote"
                  className="resSideLiAn"
                  onClick={closeSideBar}
                >
                  GET A QUOTE
                </Link>
              </li>
              <li
                className="resSideLi"
                onClick={() => localStorage.setItem("keyName", "Gateway")}
              >
                <Link
                  to="/Gateway"
                  className="resSideLiAn"
                  onClick={closeSideBar}
                >
                  GATEWAY TO THE EAST
                </Link>
              </li>
              <li className="resSideLi">
                <Link to="https://www.facebook.com/sosideasindia/" className="resSideLiAn" target="_blank">
                  Facebook <i class="fa-brands fa-facebook-f"></i>
                </Link>
              </li>
              <li className="resSideLi">
                <Link to="https://in.linkedin.com/in/sos-ideas-50b94a100" className="resSideLiAn" target="_blank">
                  Linkedin <i class="fa-brands fa-linkedin-in"></i>
                </Link>
              </li>
              <li className="resSideLi">
                <Link to="https://www.youtube.com/channel/UCqo_aMgdLwu8oOs7kcnaFcw" className="resSideLiAn" target="_blank">
                  Youtube <i class="fa-brands fa-youtube"></i>
                </Link>
              </li>
              <li className="resSideLi">
                <Link to="https://instagram.com/sosideasindia?igshid=NzZhOTFlYzFmZQ==" className="resSideLiAn" target="_blank">
                  Instagram <i class="fa-brands fa-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
