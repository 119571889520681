import Kusumasab from "../Images/Kusumasab Mitra.jpg";
import Anirban from "../Images/Anirban Sarkar.jpg";
import Ananda from "../Images/Ananda Ray.jpg";
import Kamalika from "../Images/Kamalika Ganguli.jpg";
import Manas from "../Images/Manas Maity.jpg";
import Niladri from "../Images/Niladri Deb.jpg";
import Pratyush from "../Images/Pratyush Chakraborty.jpg";
import Malay from "../Images/Malay Roy.jpg";


export const Users = [
    {
        id: 1,
        src: Kusumasab,
        alt: "img",
        name: "Kusumasab Mitra",
        profession: "Senior Vice President",
        description:` 17 years in marketing communications, I have worked at Ogilvy (Kolkata and Delhi), McCann World Group, Lowe, JWT (Contract India), Leo Burnett, &Hakuhodo India. A brand enthusiast & a team player, I have worked on top brands such as Coca Cola, Perfetti Van Melle, Dabur, Vodafone, Walmart (Best Price) DainikJagran, Steel Authority of India, Maruti Suzuki (Nexa), Lafarge Cements, KeoKarpin, Paytm, Bandhan Bank, LG and many more. Apart from launching many successful brands, I have been associated with numerous creative awards (Cannes, Effies, etc) across the above brands. I have won accolades in swimming, badminton and cricket as well.`
      },
      {
        id: 2,
        src: Anirban,
        alt: "img",
        name: "Anirban Sarkar",
        profession: "Senior Vice President",
        description:` With 22+ years of experience in Advertising and Direct Sales, I've worked with prestigious brands like Coca Cola, Ananda Bazar Patrika, PC Chandra Jewellers, Dalmia Cement, Emami, Everest Spices, and more. Beyond work, I cherish hobbies like cooking, karaoke, photography, and traveling, always seeking interesting consumer insights and brand stories. `
      },
      {
        id: 3,
        src: Ananda,
        alt: "img",
        name: "Ananda Ray",
        profession: "Senior Creative Director",
        description:`29 years. 7 agencies. 4.5 cities. Learnings from innumerable brands, client relationships and colleagues. I approach my work like a lawyer - making arguments on behalf of brands. My greatest source of pride? The ability to build relationships and grow teams. After all, client, colleague or brand, communication is key. `
      },
      {
        id: 4,
        src: Kamalika,
        alt: "img",
        name: "Kamalika Ganguli",
        profession: "Creative Director",
        description:` I am the mother of all things creative and crazy. From mothering my awesome team, to mothering my beloved brands and the coolest 7-year-old at home, I do it with a lot of love. Having worked in agencies like JWT, Bates and Rediffusion I have handled an eclectic mix of clients for 16 years, like Coca-cola, ITC, Bisk Farm, ABP, Eveready, Dabur, Khadim’s, Marico, Telegraph, Nuvoco to name a few. I simply love ad filmmaking and thrive on shoots. I enjoy Urdu poetry, music, baking, travelling and binge-watching movies.`
      },
      {
        id: 5,
        src: Manas,
        alt: "img",
        name: "Manas Maity",
        profession: "Creative Director",
        description:` Hi! I’m Manas, Creative Director at SOS Ideas. I have 14 years of experience with clients like ABP, Bisk Farm, Cars24, Century Decoratives, ITC, Paytm, PC Chandra, Shyam Steel, Times of India and others. A plate of steaming Maggi and a cup of strong tea kick-start my office hours.`
      },
      {
        id: 6,
        src: Niladri,
        alt: "img",
        name: "Niladri Deb",
        profession: "Creative Director",
        description:` Hi I am Niladri. With over 20 years of experience, I have worked for brands such as Eveready, Tata, ABP, Senco Gold, Berger Paints, Spencers, Taj, Bisk Farm, Emami, ITC and many more. Apart from being into advertising, I am also an active content creator. I have won awards like Spike Asia, SrijanSamman, and AdClub Kolkata.`
      },
      {
        id: 7,
        src: Pratyush,
        alt: "img",
        name: "Pratyush Chakraborty",
        profession: "Creative Director",
        description:` Word Dealer, Idea Trader and Story Seller. 18 years and 4 cities later, I am still busy chasing the next big idea, just like a rookie.  In my journey so far, I have walked with brands like Airtel, IndianOil, Star Sports, Paytm,Dabur, Coromandel Fertilisers, KeoKarpin and DLF. `
      },
      {
        id: 8,
        src: Malay,
        alt: "img",
        name: "Malay Roy",
        profession: "Studio Manager",
        description:` Hi, I'm Malay, Studio Manager, experiencing the world of advertising for over a couple of decades. Worked with esteemed brands like ITC, Coca cola, ABP Group, P.C Chandra, Khadim, Century Ply, Dalmia Cements, Marico, Bisk Farm to name a few. I keep watching movies and enjoy listening to music from various genre. `
      },
  ];