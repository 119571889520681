import React from "react";
import { Link } from "react-router-dom";
import "../Gateway/Gateway.css";
import video from "../../Videos/AMUL case study.mp4";
import amul1 from "../../Images/amul1.jpg";
import amul2 from "../../Images/amul2.jpg";
import amul3 from "../../Images/amul3.jpg";
import amul4 from "../../Images/amul4.jpg";
import amul5 from "../../Images/amul5.jpg";
import amul6 from "../../Images/amul6.jpg";
import amul7 from "../../Images/amul7.jpg";
import amul8 from "../../Images/amul8.jpg";
import amul9 from "../../Images/amul9.jpg";
import amul10 from "../../Images/amul10.jpg";
import amul11 from "../../Images/amul11.png";
import amul12 from "../../Images/amul12.png";
import amul13 from "../../Images/amul13.png";
import amul14 from "../../Images/amul14.jpg";
import amul15 from "../../Images/amul15.jpeg";
import amul16 from "../../Images/amul16.jpg";
import amul17 from "../../Images/amul17.jpg";
import amulmilk from "../../Images/amul-milk.jpg";
import amullogo from "../../Images/amul-logo.png";
import amulimage from "../../Images/images (1).jpg";
import amulimage2 from "../../Images/images (2).jpg";
import amulad from "../../Images/add.jpg";

function GatewayCase() {
  return (
    <>
      <section className="amul-conten">
        <div className="custContain">
          <div className="amul-section">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="amul-contenDiv">
                  <div className="logo">
                    <img className="amul-logo" src={amullogo} alt="amul-logo" />
                  </div>
                  <div className="amul-dtls">
                    <p>
                      Amul needed to have a stronger association with the
                      Bengali consumers (one of their largest markets) and
                      decided to use social media for that purpose.
                    </p>
                    <p>
                      We started the first regional-language page of Amul in
                      India and have been maintaining it since 2019, with varied
                      content and advertising that goes deep into local bengali
                      cultural nuances and connects with the audience strongly,
                      making Amul Bangla one of the top Bengali brand sites on
                      social media.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="poster-section">
                  <div className="title">
                    <p>Festive Posts – Bengal Specific</p>
                  </div>
                  <div className="poster">
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul1} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul2} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul3} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul4} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul5} />
                    </div>
                  </div>
                  <div className="title">
                    <p>Topical Posts – Bengal Specific</p>
                  </div>
                  <div className="poster">
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul6} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul7} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul8} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul9} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul10} />
                    </div>
                  </div>
                  <div className="poster">
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amulmilk} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul11} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amulimage2} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul12} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul13} />
                    </div>
                  </div>
                  <div className="poster">
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul14} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul15} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul16} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amul17} />
                    </div>
                    <div className="poster-img">
                      <img className="poster-imgDiv" src={amulimage} />
                    </div>
                  </div>
                  <div className="right-title">
                    <a href="#">Social Media page</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-12">
                <div className="advertising">
                  <div className="video adSec">
                    <video
                      controls
                      className="w-100"
                    >
                      <source src={video} type="video/mp4" />
                    </video>
                  </div>
                  <div className="advertise-section adSec">
                    <div className="add-img">
                      <img src={amulmilk} alt="advertising" />
                    </div>
                    <div className="ad-dtls">
                      <p>Misc Videos</p>
                      <a href="#">Recipe Show Please click to view</a>
                    </div>
                  </div>
                  <div className="poster-add adSec">
                    <img src={amulad} alt="advertising" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GatewayCase;
