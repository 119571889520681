import React, { useEffect } from "react";
import { useState } from "react";
import kamalika from "../../Images/Kamalika Ganguli.jpg";
import Anirban from "../../Images/Anirban Sarkar.jpg";
import Kusumasab from "../../Images/Kusumasab Mitra.jpg";
import Malay from "../../Images/Malay Roy.jpg";
import Manas from "../../Images/Manas Maity.jpg";
import Pratyush from "../../Images/Pratyush Chakraborty.jpg";
import ananda from "../../Images/Ananda Ray.jpg";
import "../People/People.css";
import { useParams } from "react-router-dom";
import { Users } from "../../userArrayList.js/User";
import { useNavigate } from "react-router-dom";

function PeopleInner() {
  const { name } = useParams();
  const navigate = useNavigate()

  const [currentUser, setCurrentuser] = useState({ name: "", src: "", profession: "", description: "" });

  console.log("name", name);
  const cmnImg = document.querySelectorAll(".commonImage");
  for (let cmn of cmnImg) {
    cmn.addEventListener("click", function (e) {
      document
        .querySelector(".detailImg")
        .setAttribute("src", `${e.target.getAttribute("src")}`);
    });
  }

  useEffect(() => {
    window.scrollTo({
      top: "0",
      left: "0",
      behavior: "smooth",
    });

    const findUser = Users.find((item) => item.name === name);

    setCurrentuser(findUser);
  }, [name]);

  return (
    <>
      <section className="peopleInnerSection">
        <div className="custContain">
          <div className="row align-items-start">
            <div className="col-lg-3 col-md-5 col-sm-12 peoplebig">
              <figure className="peopleInnerFig detailImg">
                <img src={currentUser.src} />
              </figure>
            </div>
            <div className="col-lg-5 col-md-7 col-sm-12 peopleLearnTextDiv">
              <p className="innerNameHead">
                {currentUser.name}, {currentUser.profession}
              </p>
              <p className="innerSubPara">
                {currentUser.description}
              </p>
            </div>
            <div className="col-lg-4 col-sm-12 mb-3 mb-xl-0 peoplesmall">
              <div className="row row-cols-3 mulPepInPage">
                {
                  Users.map((item) => {
                    return <figure className="peopleInnerSmallFig commonImage" onClick={() => navigate(`/people/${item.name}`)}>
                      <img src={item.src} />
                      <div className="peoInnerFigSpan"><p>{item.name}</p></div>
                    </figure>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PeopleInner;
