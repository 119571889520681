import React from "react";
import "../Gateway/Gateway.css";

// Component

import GatewayCaseStudy from "../../Component/GatewayCaseStudy";
import GatewayBanner from "../../Component/GatewayBanner";
import GatewayBrand from "../../Component/GatewayBrand";

function Gateway() {

  return (
    <>

      <GatewayBanner />

      <GatewayBrand />

      <GatewayCaseStudy />

    </>
  );
}

export default Gateway;
