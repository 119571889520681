import React from 'react'
import { Outlet } from 'react-router-dom'
import Header2 from '../Layout/Header2';
import Footer from '../Layout/Footer';

function InnerLayout() {
  return (
    <div className=''>
        <Header2 />
        <Outlet />
        <Footer />
    </div>
  )
}

export default InnerLayout