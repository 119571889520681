import React from 'react';
import "../View/People/People.css";
// import onerpeople from "../Images/onerpeople.png";
// import onerpeople from "../Images/ownerSos2.png"
import sosimage from "../../src/Images/sosimage.png"

function PeopleNew() {
    return (
        <>
            <section className="people">
                <div className="custContain">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12 people-responsibe">
                            <div className="people-dtls">
                                <div className="people-name">
                                    <p>Soubhik Payra</p>
                                </div>
                                <div className="people-conten">
                                    <p>
                                        Meet Soubhik. With over 20 years of experience in top-tier
                                        agencies like Ogilvy & Mather and Bates (among others),
                                        Soubhik stands out as the only Cannes-winning advertising
                                        professional in the City of Joy!
                                    </p>
                                    <p>
                                        With a bunch of local and regional awards under his belt,
                                        Soubhik is regarded as one of the industry’s most versatile
                                        Art Directors - his expertise spanning both traditional and
                                        new-age advertising approaches.
                                    </p>
                                    <p>
                                        Yet, there’s more to him than a stunning portfolio that
                                        showcases his work for brands such as ITC, Tata Steel,
                                        Marico, Vodafone, Bisk Farm, Pantaloons, AnandabazarPatrika
                                        and Emami, to name a few. Beyond his advertising expertise,
                                        he’s a trained painter and space designer and, when it comes
                                        to designing movie promotions, he’s the one you want behind
                                        the wheel! Things don’t stop here, though. Soubhik is also
                                        the man behind a lot of the city’s most innovative Durga
                                        Puja pandals, adding another dimension to his talents!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="oner-picture">
                                <div className="oner-img">
                                    <img src={sosimage} alt="..." />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="people-dtls">
                                <div className="people-name">
                                    <p>Souvik Misra</p>
                                </div>
                                <div className="people-conten">
                                    <p>
                                        A creative force unlike another, Souvik has spent almost three
                                        decades in advertising. Having served as the Creative Head at leading
                                        agencies like Ogilvy, Bates and Contract, his experience extends all the
                                        way to Bangladesh, Myanmar and Singapore. Highly awarded, Souvik helped
                                        Bates win the coveted Agency of the Year title for 7 consecutive years.
                                    </p>
                                    <p>
                                        In 2014, he took the plunge and founded SoS Ideas,
                                        which has been making waves ever since.
                                    </p>
                                    <p>
                                        His impressive roster of clients includes Coca-Cola,
                                        Tata Steel, UNICEF, Amul, Vodafone,
                                        Paytm, Dabur, Marico and Century Ply, to mention a few.
                                    </p>
                                    <p>
                                        Souvik’s standing in the industry has resulted in him serving
                                        as jury member at several advertising awards, including Abbies,
                                        Ad Club Kolkata and Sangbad Pratidin Srijan Samman.

                                    </p>
                                    <p>
                                        Yet, there’s more. An active musician and singer-songwriter;
                                        he has contributed to several films, music albums and ad jingles,
                                        including the National
                                        Award-winning movie ‘Ranjana Ami Aar Aashbo Na’!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PeopleNew