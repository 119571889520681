import React from "react";
import "../../View/Contact/Contact.css";

function Contact() {
  return (
    <>
      <section className="contact">
        <div className="custContain">
          <div className="contactbody">
            <div className="contactmap">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.738222222581!2d88.34992067443159!3d22.514002835117136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0270cd702b3d25%3A0x3737a00df276622c!2s77a%2C%20Raja%20Basanta%20Roy%20Rd%2C%20Lake%20Market%2C%20Kalighat%2C%20Kolkata%2C%20West%20Bengal%20700029!5e0!3m2!1sen!2sin!4v1694611329725!5m2!1sen!2sin"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="map"
              />
            </div>

            <div className="contactText">
              <h5 className="contacthead">SoS Ideas</h5>
              <p className="contactpara">77A, Raja Basanta Roy Road</p>
              <p className="contactpara">Lake Market, Kalighat, Kolkata</p>
              <p className="contactpara">West Bengal - 700029.</p>
              <p className="contactpara">033 4603 1697</p>
              <p className="contactmail">sosideasindia@gmail.com</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
