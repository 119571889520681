import React, { useEffect } from 'react';
import "../View/People/People.css";
import { useState } from "react";
import Adrija from "../Images/Adrija Kundu.jpg";
import Amanda from "../Images/Amanda Adrienne Banerji.jpg";
import Anisha from "../Images/Anisha Datta.jpg";
import ArindamB from "../Images/Arindam Bhattacharjee.jpg";
import ArindamM from "../Images/Arindam Mallick.jpg";
import Arpan from "../Images/Arpan Halder.jpg";
import Asima from "../Images/Asima Biswas.jpg";
import Buddhadeb from "../Images/Buddhadeb Mahato.jpg";
import Debabrata from "../Images/Debabrata Biswas.jpg";
import Dhriti from "../Images/Dhriti Roy Chaudhuri.jpg";
import Dimendra from "../Images/Dimendra Nath Ghosh Hazra.jpg";
import Ibrahim from "../Images/Ibrahim Sheikh.jpg";
import Indrajit from "../Images/Indrajit Saha.jpg";
import Ishita from "../Images/Ishita Chakraborty.jpg";
import KathakaliG from "../Images/Kathakali Ghosh.jpg";
import KathakaliR from "../Images/Kathakali Rudra.jpg";
import Koushiki from "../Images/Koushiki Mondal.jpg";
import Meheli from "../Images/Meheli Das.jpg";
import Namrata from "../Images/Namrata Banerji.jpg";
import Pathik from "../Images/Pathik Panja.jpg";
import Piyali from "../Images/Piyali Mukherjee Kaviraj.jpg";
import Rahila from "../Images/Rahila Sayed.jpg";
import Rajendranath from "../Images/Rajendranath Mondal.jpg";
import Rameez from "../Images/Rameez Ahmed.jpg";
import Sanjib from "../Images/Sanjib Mondol.jpg";
import Saptadip from "../Images/Saptadip Dey Sarkar.jpg";
import Saptaswa from "../Images/Saptaswa Mukerjee.jpg";
import Sayani from "../Images/Sayani Ghose.jpg";
import SKSalman from "../Images/SK Salman.jpg";
import Sohini from "../Images/Sohini Basu.jpg";
import Somduttya from "../Images/Somduttya Chatterjee.jpg";
import Sudipta from "../Images/Sudipta Dutta.jpg";
import Sumanta from "../Images/Sumanta Adhikari.jpg";
import Sumon from "../Images/Suman Mondol.jpg";
import Sutanu from "../Images/Sutanu Kumar Roy.jpg";
import Suvendu from "../Images/Suvendu Roy.jpg";
import Tanmoy from "../Images/Tanmoy Das.jpg";
import Ujjaini from "../Images/Ujjaini Ganguly.jpg";
import TrailblazersModal from './Modal/TrailblazersModal';
import toast from 'react-hot-toast';
import HttpClient from "../utils/HttpClient";

function Trailblazer() {

    const [BlazersOpen, setBlazersOpen] = useState(false);
    const [trailblazerData, setTrailblazerData] = useState([]);
    const [singleData, setSingleData] = useState({});

    // console.log(singleData,"Clicked Trailblazer:")
    const openBlazers = () => {
        setBlazersOpen(true);
    };

    const getTrailblazers = async () => {
        const res = await HttpClient.requestData("user-view-people-trailblazer", "GET", {})
        if (res && res?.status) {
            setTrailblazerData(res?.data)
            //   console.log(res?.data?._id,"uuuuu")
            //   setId(res.data?._id)
        }
        else {
            toast.error(res?.message)
        }
    }

    useEffect(() => {
        getTrailblazers()
    }, [])

    console.log("xxxx", trailblazerData);
    const loop = [
        {
            id: 1,
            src: Adrija,
            alt: "img",
            name: "Adrija Kundu",
            profession: "Brand Supervisor",
        },
        {
            id: 2,
            src: Amanda,
            alt: "img",
            name: "Amanda Adrienne Banerji",
            profession: "Account Executive",
        },
        {
            id: 3,
            src: Anisha,
            alt: "img",
            name: "Anisha Datta",
            profession: "Senior Brand Supervisor",
        },
        {
            id: 4,
            src: ArindamB,
            alt: "img",
            name: "Arindam Bhattacharjee",
            profession: "Sr. Accounts Executive",
        },
        {
            id: 5,
            src: ArindamM,
            alt: "img",
            name: "Arindam Mallick",
            profession: "Creative Group Head",
        },
        {
            id: 6,
            src: Arpan,
            alt: "img",
            name: "Arpan Halder",
            profession: "Sr. Art Director ",
        },
        {
            id: 7,
            src: Asima,
            alt: "img",
            name: "Asima Biswas",
            profession: "Front Desk Executive & Admin Support",
        },
        {
            id: 8,
            src: Buddhadeb,
            alt: "img",
            name: "Buddhadeb Mahato",
            profession: "Studio Artist",
        },
        {
            id: 9,
            src: Debabrata,
            alt: "img",
            name: "Debabrata Biswas",
            profession: "Group Head",
        },
        {
            id: 10,
            src: Dhriti,
            alt: "img",
            name: "Dhriti Roy Chaudhuri",
            profession: "Copywriter",
        },
        {
            id: 11,
            src: Dimendra,
            alt: "img",
            name: "Dimendra Nath Ghosh Hazra",
            profession: "Studio Artist",
        },
        {
            id: 12,
            src: Ibrahim,
            alt: "img",
            name: "Ibrahim Sheikh",
            profession: "Studio Artist",
        },
        {
            id: 13,
            src: Indrajit,
            alt: "img",
            name: "Indrajit Saha",
            profession: "Studio Artist",
        },
        {
            id: 14,
            src: Ishita,
            alt: "img",
            name: "Ishita Chakraborty",
            profession: "Group Head",
        },
        {
            id: 15,
            src: KathakaliG,
            alt: "img",
            name: "Kathakali Ghosh",
            profession: "Copywriter",
        },
        {
            id: 16,
            src: KathakaliR,
            alt: "img",
            name: "Kathakali Rudra",
            profession: "Copywriter",
        },
        {
            id: 17,
            src: Koushiki,
            alt: "img",
            name: "Koushiki Mondal",
            profession: "Jr. Visualiser",
        },
        {
            id: 18,
            src: Meheli,
            alt: "img",
            name: "Meheli Das",
            profession: "Senior Brand Supervisor",
        },
        {
            id: 19,
            src: Namrata,
            alt: "img",
            name: "Namrata Banerji",
            profession: "Account Executive",
        },
        {
            id: 20,
            src: Pathik,
            alt: "img",
            name: "Pathik Panja",
            profession: "Art Director",
        },
        {
            id: 21,
            src: Piyali,
            alt: "img",
            name: "Piyali Mukherjee Kaviraj",
            profession: "Copy Supervisor",
        },
        {
            id: 22,
            src: Rahila,
            alt: "img",
            name: "Rahila Sayed",
            profession: "Junior Copywriter",
        },
        {
            id: 23,
            src: Rajendranath,
            alt: "img",
            name: "Rajendranath Mondal",
            profession: "Sr. Finance Manager ",
        },
        {
            id: 24,
            src: Rameez,
            alt: "img",
            name: "Rameez Ahmed",
            profession: "Group Brand Manager",
        },
        {
            id: 25,
            src: Sanjib,
            alt: "img",
            name: "Sanjib Mondol",
            profession: "Studio Artist",
        },
        {
            id: 26,
            src: Saptadip,
            alt: "img",
            name: "Saptadip Dey Sarkar",
            profession: "Art Supervisor",
        },
        {
            id: 27,
            src: Saptaswa,
            alt: "img",
            name: "Saptaswa Mukerjee",
            profession: "Sr. Brand Supervisor",
        },
        {
            id: 28,
            src: Sayani,
            alt: "img",
            name: "Sayani Ghose",
            profession: "Group Account Manager",
        },
        {
            id: 29,
            src: SKSalman,
            alt: "img",
            name: "SK Salman",
            profession: "Accounts Executive",
        },
        {
            id: 30,
            src: Sohini,
            alt: "img",
            name: "Sohini Basu",
            profession: "Sr. Account Executive",
        },
        {
            id: 31,
            src: Somduttya,
            alt: "img",
            name: "Somduttya Chatterjee",
            profession: "Group Brand Manager",
        },
        {
            id: 32,
            src: Sudipta,
            alt: "img",
            name: "Sudipta Dutta",
            profession: "Art Director",
        },
        {
            id: 33,
            src: Sumanta,
            alt: "img",
            name: "Sumanta Adhikari",
            profession: "Senior Art Director",
        },
        {
            id: 34,
            src: Sumon,
            alt: "img",
            name: "Suman Mondol",
            profession: "Studio Artist",
        },
        {
            id: 35,
            src: Sutanu,
            alt: "img",
            name: "Sutanu Kumar Roy",
            profession: "Jr. Visualiser",
        },
        {
            id: 36,
            src: Suvendu,
            alt: "img",
            name: "Suvendu Roy",
            profession: "Art Director",
        },
        {
            id: 37,
            src: Tanmoy,
            alt: "img",
            name: "Tanmoy Das",
            profession: "Art Supervisor",
        },
        {
            id: 38,
            src: Ujjaini,
            alt: "img",
            name: "Ujjaini Ganguly",
            profession: "Copy Supervisor",
        },
    ];

    // function popTextDetail(e) {
    //     console.log(e.target);
    // }


    function popTextDetail(e, image) {
        setSingleData(image)
        openBlazers();
    }

    return (
        <>
            <section className="public">
                <div className="custContain">
                    <div className="public-title">
                        <p>Trailblazers</p>
                    </div>
                    <div className="public-section row"
                    //  onClick={popTextDetail} 
                    >
                        {trailblazerData?.map((image) => {
                            return (
                                <div className="col-lg-2 col-md-2 col-sm-3 col-4 publicCol"
                                    // onClick={openBlazers} 
                                    onClick={(e) => popTextDetail(e, image)}
                                >
                                    <div className="public-img trail-img">
                                        <img key={image.id} src={image.image ? image.image : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} alt={image.alt} />

                                        {/* <div className="public-name">
                                            <p className="publicpara1"></p>
                                            <p className="publicpara2"></p>
                                        </div> */}

                                        <div className="dropdown">
                                            <button className="dropbtn">
                                                <span className="dropText">{image.name}</span>
                                                <span className="dropText2">{image.designation}</span>
                                            </button>

                                            {/* <div className="dropdown-content">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetuer adipiscing
                                                    elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                                                    dolore magna aliquam erat volutpat. Ut wisi enim ad
                                                    minim veniam, quis nostrud exerci tation ullamcorper
                                                    suscipit lobortis nisl ut aliquip ex ea commodo
                                                    consequat.
                                                </p>
                                                </div> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            {BlazersOpen && <TrailblazersModal closeBlazers={setBlazersOpen} singleData={singleData} />}
        </>
    )
}

export default Trailblazer