import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "../../src/View/HomePage/Index.css";
import aaroanondo from "../Images/Aaro Anondo.jpg";
import Amul from "../Images/Amulmain.jpg";
import Bandhan from "../Images/Bandhan Bank.jpg";
import Cars24 from "../Images/Cars24.jpeg";
import Sardarudham from "../Images/Sardar Udham.jpg";
import Rollick from "../Images/Rollick.jpg";
import paytmad from "../Images/Paytmad.jpg";
import msp from "../Images/MSP.jpg";
import Lemme from "../Images/Lemme.jpg";
import Keokarpin from "../Images/keo karpin.jpg";
import Merlin from "../Images/Merlin.jpg";
import Biskfarm from "../Images/Bisk Farm.jpg";
import Rupa from "../Images/RUPA.jpg";
import whitearrow from "../Images/whitearrow.png";
import HttpClient from '../utils/HttpClient';
import CustomLoader from '../CustomComponents/loader/CustomLoader';


function IndexImages() {

    // const loop = [{ id: 1, src: aaroanondo, alt: "img", name: "Aaro Anondo", }, { id: 1, src: Amul, alt: "img", name: "Amul", }, { id: 1, src: Bandhan, alt: "img", name: "Bandhan Bank", }, { id: 1, src: Cars24, alt: "img", name: "Cars 24", }, { id: 1, src: Sardarudham, alt: "img", name: "Sardar Udham", }, { id: 1, src: Rollick, alt: "img", name: "Rollick", }, { id: 1, src: paytmad, alt: "img", name: "Paytm", }, { id: 1, src: msp, alt: "img", name: "MSP", }, { id: 1, src: Lemme, alt: "img", name: "Lemme", }, { id: 1, src: Keokarpin, alt: "img", name: "Keo Karpin", }, { id: 1, src: Merlin, alt: "img", name: "Merlin", },]

    const navigate = useNavigate();
    const [workData, setWorkData] = useState([]);
    const [loading, setLoading] = useState(false);

    // console.log("workData", workData)

    // getting data prioritywise
    const getDataPriority = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("priority-wise-work", "GET");
        // console.log("resPriority", res);
        if (res && res?.status) {
            setWorkData(res?.data);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        getDataPriority();
    }, [])

    return (
        <>
            <CustomLoader loading={loading} />
            <section className="indeximages">
                <div className="custContain">
                    <div className="indexBig">

                        {/* first work */}
                        <div className="bigindexDiv">
                            <Link to={`/WorkInner/${workData?.[0]?._id}`}>
                                <div className="indexBigImgWrap">
                                    <img src={workData?.[0]?.thumbNail} alt="advertisement" />
                                    <div className="indexTextDiv">
                                        <p className="indexText">{workData?.[0]?.title}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        {/* second work */}
                        <div className="bigindexDiv">
                            <Link to={`/WorkInner/${workData?.[1]?._id}`}>
                                <div className="indexBigImgWrap">
                                    <img src={workData?.[1]?.thumbNail} />
                                    <div className="indexTextDiv">
                                        <p className="indexText">{workData?.[1]?.title}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    {/* all works */}
                    <div className="indexsmall">
                        {workData?.slice(2).map((item) => {
                            return (
                                <div className="indexDiv" key={item?._id}>
                                    <Link to={`/WorkInner/${item?._id}`}>
                                        <div className="indexSmallImgWrap">
                                            <img src={item.thumbNail} />
                                            <div className="indexsmallDiv">
                                                <p className="indexText">{item.title}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}

                        {/* see more */}
                        <div className="indexDiv" style={{ cursor: "pointer" }} onClick={() => navigate("/Work")}>
                            <div className="Indexmore">
                                <p>See more</p>
                                <div className="whtarrowDiv">
                                    <img src={whitearrow} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndexImages