import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
import jeerawonder from "../Images/Jeera Wonder ad_Thumbnail.jpg";
import top from "../Images/Bisk Farm_The TOP.jpg";
import marie from "../Images/BiskfarmRichMarie.jpg";
import googly from "../Images/biskthumb.jpg";
import arrowleft from "../Images/arrowleft.png";
import arrowright from "../Images/arrowright.png";

function ShowSliderBisk() {
  const [swiperRef, setSwiperRef] = useState(null);

  const prevHandler = () => {
    swiperRef.slidePrev();
  };

  const nextHandler = () => {
    swiperRef.slideNext();
  };

  return (
    <>
      <section className="showSlider">
        <div className="custContain">
          <div className="showSliderTop">
            <h3 className="showSliderHead">Bisk Farm</h3>
          </div>
          <div className="showSliderbtm">
            <div className="showprev" onClick={prevHandler}>
              <img src={arrowleft} />
            </div>
            <Swiper
              slidesPerView={4}
              spaceBetween={10}
              loop={true}
              navigation={{
                clickable: true,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                575: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1399:{
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
              modules={[Navigation]}
              onSwiper={(swiper) => setSwiperRef(swiper)}
              className="showSwiper"
            >
              <SwiperSlide>
                <div className="showsliderwrap">
                  <img src={jeerawonder} alt="img" />
                  <div className="showsliderText">
                    <p>Jeera Wonders</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="showsliderwrap">
                  <img src={top} alt="img" />
                  <div className="showsliderText">
                    <p>Bisk Farm The Top</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="showsliderwrap">
                  <img src={marie} alt="img" />
                  <div className="showsliderText">
                    <p>Bisk Farm Marie</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="showsliderwrap">
                  <img src={googly} alt="img" />
                  <div className="showsliderText">
                    <p>Googly</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="shownext" onClick={nextHandler}>
              <img src={arrowright} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ShowSliderBisk;
