import React, { useState, useEffect } from "react";
import "../Career/Career.css";
import careerimg from "../../Images/careerimg.jpg";
import CareerForm from "../../Component/Modal/CareerForm";
import HttpClient from "../../utils/HttpClient";

function Career() {
  const [position, setPosition] = useState([]);
  const [modalA, setModalA] = useState(false);
  function openCareerForm() {
    setModalA(true);
  }
  const getCategoryData = async () => {
    const res = await HttpClient.requestData('user-view-career-position', "GET", {});
    console.log(res)
    if (res && res?.status) {
      setPosition(res?.data)

    }
    else {
      console.log(res?.msg)
    }
  }
  useEffect(() => {
    getCategoryData();
  }, []);
  return (
    <>
      <section className="career">
        <div className="custContain">
          <div className="careerbody">
            <div className="careerimg">
              <div className="careerimgwrap">
                <img src={careerimg} alt="img" />
              </div>
            </div>
            <div className="careerdetails">
              <h3 className="careerHead">Work With Us</h3>
              <p className="careerpara">
                At SoS, we transform challenges into triumphs and concepts into
                campaigns! If you’re passionate about crafting compelling
                narratives, designing captivating visuals and making brands
                shine, you’ve found your home!
              </p>
              <p className="careerpara">
                Collaborate with some of the brightest minds in the industry and
                work on exciting projects for top-tier clients. Your ideas won’t
                just be heard; they’ll be celebrated, refined and brought to
                life!
              </p>
              <div className="openposition">
                <h5 className="openhead">Open Positions</h5>
                <p className="openpara">
                  {
                    position?.map((item) => <span>{item?.name} |</span>)

                  }
                </p>
              </div>
              <div className="careJoinDiv">
                <p className="careerpara">
                  If you're interesting, we're interested! Mail us at
                </p>
                <div className="emaiJoinBtnsDiv">
                  <button className="careerapplybtn">
                    sosideasindia@gmail.com
                  </button>
                  <button className="joinUsBtn" onClick={openCareerForm}>
                    Join Us!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {modalA && <CareerForm closeCareModal={setModalA} />}
    </>
  );
}

export default Career;
