import React from 'react';
import "../View/Gateway/Gateway.css";
import GatewaySwiperLeft from "./GatewaySwiperLeft.js";
import GatewaySwiperRight from './GatewaySwiperRight';

function GatewayBrand() {
    return (
        <>
            <section className="gatewaybrand">
                <div className="custContain">
                    <div className="gatebrandMain">
                        <div className="gatewaybranditem" id="gateitem1">
                            <h5 className="gatebrandhead">
                                Regional duties for national brands
                            </h5>
                            <GatewaySwiperLeft />
                        </div>
                        <div className="gatewaybranditem">
                            <h5 className="gatebrandhead">
                                Building champion regional brands
                            </h5>
                            <GatewaySwiperRight />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GatewayBrand